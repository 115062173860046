import { Divider, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { Modal, ModalActions, ModalButton, ModalContents, ModalFormField, ModalHeader } from "./modal";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect } from "react";
import { isUrl } from "../../urls";
import { getClipboardText } from "../../features/clipboard";


const schema = yup.object({
    url: yup.string().test("is-url", "Invalid URL", (value) => !!value && isUrl(value)).required()
}).required();

export const UploadLinkModal = ({ open, onClose, onAccept }: { open: boolean, onClose: () => void; onAccept: (name: string) => void }) => {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    useEffect(() => {
        const getUrlFromClipboard = async () => {
            const clipboardText = await getClipboardText();
            const trimmedClipboardText = clipboardText.trim();
            const isValid = await schema.isValid({ url: trimmedClipboardText });
            if (isValid) {
                setValue("url", trimmedClipboardText)
            }
        }

        if (open) {
            getUrlFromClipboard();
        }

    }, [open, setValue]);

    const onSubmit = (data: yup.InferType<typeof schema>) => {
        reset();
        onAccept(data.url);
    }

    const doOnClose = () => {
        reset();
        onClose();
    }

    return <Modal open={open} onClose={onClose} >
        <ModalContents>
            <ModalHeader>
                Add Link
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form" id="upload-link-modal-form">
                <ModalFormField title="Url">
                    <TextField error={!!errors.url} helperText={errors.url?.message || ""} {...register("url")} fullWidth placeholder="http://" />
                </ModalFormField>
            </form>
        </ModalContents>
        <Divider />
        <ModalActions>
            <ModalButton onClick={doOnClose} variant="text" color="primary" size="large">Close</ModalButton>
            <ModalButton form="upload-link-modal-form" type="submit" variant="contained" color="contrast" size="large">Add</ModalButton>
        </ModalActions>
    </Modal>;
};
