import { useAuthenticator } from '@aws-amplify/ui-react';
import { AmplifyUser } from '@aws-amplify/ui';
import { getGuestSessionState, guestSessionCookieExists } from './guest';
import { Auth } from 'aws-amplify';
import { User, userFromAmplifyUser } from './types';

const ADMIN_GROUP_NAME = 'Admin';

const checkIsAdmin = (user: AmplifyUser) => {
    const groups = user?.getSignInUserSession()?.getIdToken().payload['cognito:groups'];
    if (!groups) {
        return false;
    }
    return groups.includes(ADMIN_GROUP_NAME);
}

const getGuestUser = () => {
    const state = getGuestSessionState();
    return {
        ...state,
        verified: true,
        email: "guest@brua.io"
    } as User;
}

export function useAuth(bypassCache?: boolean) {

    const isGuest = guestSessionCookieExists();    

    const { user: amplifyUser, authStatus } = useAuthenticator(context => [context.user, context.authStatus]);
    
    const user = amplifyUser ? userFromAmplifyUser(amplifyUser) : isGuest ? getGuestUser() : undefined;

    const loading = authStatus === "configuring"

    const isAuthenticated = !!amplifyUser;

    // @ts-ignore
    const isVerified = amplifyUser?.attributes?.email_verified === true;

    const isAdmin = amplifyUser ? checkIsAdmin(amplifyUser) : false;

    return { user, loading, isAuthenticated, isVerified, isAdmin, isGuest, amplifyUser};
}

export const isAuthenticated = async () => {
    try {
        await Auth.currentSession();
        return true;
    } catch (e) {
        return false;
    }
}

export const isGuest = async () => {
    return guestSessionCookieExists();
}
