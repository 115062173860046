import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchHubSessions,  selectGetHubSessionsState } from "./publishedSessionSlice";
import { useAuth } from "../../auth";

export default function HubSessionsLoaderComponent() {

    const { isAuthenticated } = useAuth();

    const publishedSessionsState = useAppSelector(selectGetHubSessionsState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isAuthenticated && publishedSessionsState === "idle")
            dispatch(fetchHubSessions());
    }, [isAuthenticated, publishedSessionsState, dispatch]);
    return null;
}