import { createSlice } from "@reduxjs/toolkit";
import { SnackbarKey } from "notistack";
import { RootState } from "../../app/store";

export interface Notification {
    key: SnackbarKey
    message: string
    options: any
    dismissed: boolean
}

interface NotificationState {
    notifications: Notification[]
}

const initialState: NotificationState = {
    notifications: []
}

const defaultOptions = {
    autoHideDuration: 5000,
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
    },
}


const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        enqueueSnackbar: (state, action) => {
            const notification = {
                message: action.payload.message,
                options: { ...defaultOptions, ...action.payload.options },
                key: action.payload.key ? action.payload.key : new Date().getTime() + Math.random(),
                dismissed: false
            };
            return { ...state, notifications: [...state.notifications, notification] }
        },
        closeSnackbar: (state, action) => {
            return { ...state, notifications: state.notifications.map(notification => notification.key === action.payload ? { ...notification, dismissed: true } : notification) }
        },
        removeSnackbar: (state, action) => {
            return { ...state, notifications: state.notifications.filter(notification => notification.key !== action.payload) }
        }
    }
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = notificationsSlice.actions;
export const selectNotifications = (state: RootState) => state.notifications.notifications;
export default notificationsSlice.reducer;