import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import { subscribeToInteraction, unsubscribeFromInteraction } from '../middleware/socket';


export const InteractionConnector = ({ interactionId, sessionId }: { interactionId?: string, sessionId?: string }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {

        const disconnect = () => {
            if (interactionId && sessionId) {
                dispatch(unsubscribeFromInteraction({ sessionId: sessionId, interactionId: interactionId }));
            }
        }

        if (interactionId && sessionId) {
            dispatch(subscribeToInteraction({ sessionId: sessionId, interactionId: interactionId }));
        }

        return () => {
            disconnect();
        }
    }, [interactionId, sessionId, dispatch]);

    return <></>

}