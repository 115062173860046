import React from "react";

export const usePersistentState = <T,>(key: string, defaultValue: T): [T, (value: T) => void] => {
    const [value, setValue] = React.useState<T>(() => {
        const stickyValue = window.localStorage.getItem(key);
        try {
            return stickyValue !== null
                ? JSON.parse(stickyValue)
                : defaultValue;
        } catch (error) {
            console.error("Failed to parse localStorage value", error);
            return defaultValue;
        }
    });
    
    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
};
