import { useCallback, useEffect, useRef, useState } from "react";
import { AvatarProps, Badge, Button, Card, Chip, ClickAwayListener, Collapse, Divider, IconButton, LinearProgress, List, Popper, TextField, ToggleButton, ToggleButtonGroup, useColorScheme } from "@mui/material";
import { InteractionEvent, Member, Comment } from "../../types";
import { useAppDispatch } from '../../app/hooks';
import { ContentReference, fetchAddInteractionEventFeedback, setCurrentContentReferences } from "../../features/session/sessionSlice";
import { ListItem } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box } from "@mui/material";
import Markdown from "react-markdown";
import { getMemberInfoAvatar, getMemberInfoById, MemberInfo } from "../../members";
import { Avatar, Typography } from "@mui/material";
import { datetimeStringToDatetimeReadableNoWeekday, datetimeStringToHourReadable } from "../../time";
import { useAuth } from "../../auth";
import { CollapsibleList } from "../collapsibleList";
import { ReactComponentToolComponent } from "./tools/reactComponentTool";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark as darkCodeTheme, oneLight as lightCodeTheme } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { preprocessLaTeX, rehypePlugins, remarkPlugins } from "../../features/markdown";
import { useSelector } from "react-redux";
import { fetchCreateInteractionEventComment, fetchDeleteComment, fetchEditComment, selectCommentsForInteractionEvent } from "../../features/comments/commentsSlice";
import { usePopover } from "../../hooks/usePopover";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

const CONTENT_CHUNK_SEPARATOR = "_"

export type OnEventClicked = (event: InteractionEvent) => void;

export function QaEventsContainer({ events, members: _members, onClick, sessionId, interactionId }: { events: InteractionEvent[], members: Member[], onClick?: OnEventClicked, sessionId: string, interactionId: string }) {
    const { user, loading } = useAuth();
    const [eventsLength, setEventsLength] = useState(events.length);
    const eventsEndRef = useRef<null | HTMLLIElement>(null);
    const location = useLocation();
    const interactionEventId = location.state?.interactionEventId;

    useEffect(() => {
        if (!interactionEventId) {
            scrollToTarget();
        }
    }, [interactionEventId]);

    useEffect(() => {
        if (events.length !== eventsLength) {
            setEventsLength(events.length);
            scrollToTarget();
        }
    }, [events.length, eventsLength]);

    if (loading) {
        return <></>
    }

    const thisMember = { role: "VISITOR", user: user } as Member;

    const members = [..._members, thisMember];

    const scrollToTarget = () => {
        eventsEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const questions = events.filter((event) => event.event_type === "QueryEvent");
    const qaPairs = questions.map((question) => {
        const answer = events.find((event) => event.event_type === "TextResponseEvent" && event.query_id === question.id);
        const tools = events.filter((event) => event.event_type === "ToolResponseEvent" && event.query_id === question.id);

        return { question, answer, tools };
    })

    return <List className="interaction-container-events hide-scrollbar">
        {qaPairs.map(({ question, answer, tools }, index) => <QuestionCard key={question.id} question={question} answer={answer} tools={tools} members={members} sessionId={sessionId} interactionId={interactionId} />)}
        <Box ref={eventsEndRef} />
    </List>
}

const QuestionCard = ({ question, answer, tools, members, sessionId, interactionId }: { question: InteractionEvent, answer?: InteractionEvent, tools: InteractionEvent[], members: Member[], sessionId: string, interactionId: string }) => {
    const empty = !answer?.response && tools.length === 0;
    const isGenerating = tools.some((tool) => tool.is_generating) || answer?.is_generating || false;
    const location = useLocation();
    const interactionEventId = location.state?.interactionEventId;
    const commentId = location.state?.commentId;

    const topRef = useRef<null | HTMLLIElement>(null);

    useEffect(() => {
        if (interactionEventId === question.id && !commentId) {
            topRef.current?.scrollIntoView({ behavior: "auto" });
        }
    }, [commentId, interactionEventId, question.id]);

    return <ListItem ref={topRef} >
        <Card className="qa-events-container-card no-hover">
            <QuestionHeader question={question} members={members} />
            <Divider />
            {
                tools.map((tool, index) => <ToolComponent key={tool.id} tool={tool} />)
            }
            {
                answer?.response &&
                <InteractionEventTextComponent text={answer?.response || "No response"} />
            }
            {empty && <LinearProgress />}
            <QuestionFooter members={members} question={question} answerEmpty={empty} isGenerating={isGenerating} sessionId={sessionId} interactionId={interactionId} />
        </Card>
    </ListItem>
}

const SummaryToolComponent = ({ tool }: { tool: InteractionEvent }) => {
    return <Markdown remarkPlugins={remarkPlugins} rehypePlugins={rehypePlugins} children={tool.response}></Markdown>
}

const InnerToolComponent = (tool: InteractionEvent) => {
    if (tool.tool_name === "summarize_document") {
        return <SummaryToolComponent tool={tool} />
    }
    if (tool.tool_name === "create_react_component") {
        return <ReactComponentToolComponent tool={tool} />
    }
    return SummaryToolComponent({ tool });
}

const ToolSourceComponent = ({ tool }: { tool: InteractionEvent }) => {
    const { mode } = useColorScheme();
    const theme = mode === "dark" ? darkCodeTheme : lightCodeTheme;
    const source = tool.response || "";
    if (tool.tool_output_type === "TEXT_REACT") {
        return <SyntaxHighlighter language="jsx" style={theme}>
            {source}
        </SyntaxHighlighter>
    } else {
        return <Markdown remarkPlugins={remarkPlugins} rehypePlugins={rehypePlugins} children={tool.response}></Markdown>
    }
}

type ToolDisplayType = "code" | "preview";

const toolDisplayNames: { [key: string]: string } = {
    "summarize_document": "Generated summary",
    "create_react_component": "React component",
    "default": "Tool output"
}

const toolReferenceToContentReference = (reference: string | string[]): ContentReference => {
    if (Array.isArray(reference)) {
        return { contentId: reference[0], chunkId: reference[1] };
    } else {
        return { contentId: reference };
    }
}

const ToolComponent = ({ tool }: { tool: InteractionEvent }) => {
    const [isGenerating, setIsGenerating] = useState(tool.is_generating);
    const [display, setDisplay] = useState<ToolDisplayType>(tool.is_generating ? "code" : "preview");
    const dispatch = useAppDispatch();

    const hasOnlyPreview = ["TEXT_RAW", "TEXT_MARKDOWN"].includes(tool.tool_output_type || "");

    const onRefClick = (contentReference: ContentReference) => {
        dispatch(setCurrentContentReferences([contentReference]));
    };

    useEffect(() => {
        if ((isGenerating !== tool.is_generating) && !tool.is_generating) {
            setDisplay("preview");
        }
        setIsGenerating(tool.is_generating);
    }, [isGenerating, tool.is_generating])

    const onChangeDisplayType = (event: React.MouseEvent<HTMLElement>, newDisplayType: ToolDisplayType) => {
        if (newDisplayType === null) return;
        setDisplay(newDisplayType);
    }

    return <Box className="tool-component">
        {!hasOnlyPreview &&
            <ToggleButtonGroup size="small" value={display} aria-label="display type" onChange={onChangeDisplayType} exclusive>
                <ToggleButton value="code" aria-label="code">
                    Code
                </ToggleButton>
                <ToggleButton value="preview" aria-label="preview" disabled={isGenerating}>
                    Preview
                </ToggleButton>
            </ToggleButtonGroup>
        }
        <CollapsibleList title={toolDisplayNames[tool.tool_name || "default"]} collapseClassName="tool-component-collapse" defaultOpen>
            <Box className="tool-component-contents">
                {
                    display === "preview" || hasOnlyPreview ?
                        <InnerToolComponent {...tool} /> :
                        <ToolSourceComponent tool={tool} />
                }
                <Box>
                    {
                        tool.references?.map((reference, index) => {
                            const contentReference = toolReferenceToContentReference(reference);
                            const { contentId, chunkId } = contentReference;
                            return <span key={index} content-id={contentId} chunk-id={chunkId} className="content-reference" onClick={() => { onRefClick(contentReference) }}><InfoOutlinedIcon /></span>;
                        })
                    }
                </Box>
            </Box>
        </CollapsibleList>
    </Box>

}

const HeaderAvatar = ({ memberInfo }: { memberInfo?: MemberInfo }) => {
    const avatarProps = { sx: { width: '24px', height: '24px', fontSize: "0.7em" } };
    const avatar = memberInfo ? getMemberInfoAvatar(memberInfo, avatarProps) : <UnknownMemberAvatar props={avatarProps} />;
    const displayName = memberInfo ? memberInfo.displayName : "Unknown";
    return <Box sx={{ display: 'flex', gap: '8px' }}>
        {avatar} <Typography variant="body2" color="text.secondary"> {displayName}</Typography>
    </Box>
}

const QuestionFeedback = ({ open, onClose, submitFeedback }: { open: boolean, onClose: () => void, submitFeedback: ((feedback: string[]) => void) }) => {

    const defaultFeedback = ["Incorrect information", "Malformed output", "Missing sources"]

    const [feedbackState, setFeedbackState] = useState<Set<string>>(new Set());
    const [otherFeedback, setOtherFeedback] = useState<string>("");

    const reset = () => {
        setFeedbackState(new Set());
        setOtherFeedback("");
    }

    const onSubmit = () => {

        const feedback = Array.from(feedbackState);
        if (otherFeedback) {
            feedback.push(otherFeedback);
        }

        if (feedback.length === 0) {
            return;
        }

        submitFeedback(feedback);

        reset();
        onClose();
    }

    return <Collapse in={open}>
        <DividerWithText text="Feedback" />
        <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", paddingTop: "8px", alignItems: "start" }}>
            <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                {
                    defaultFeedback.map((feedback) => {
                        const checked = feedbackState.has(feedback);
                        return <Chip key={feedback} variant={checked ? "filled" : "outlined"} label={feedback} onClick={() => {
                            const newFeedbackState = new Set(feedbackState);
                            if (checked) {
                                newFeedbackState.delete(feedback);
                            } else {
                                newFeedbackState.add(feedback);
                            }
                            setFeedbackState(newFeedbackState);
                        }} />
                    })

                }
            </Box>
            <Box sx={{ width: "100%" }}>
                <Typography variant="body1">Other feedback (optional)</Typography>
                <TextField fullWidth value={otherFeedback} onChange={(e) => setOtherFeedback(e.target.value)} />
            </Box>
            <Button sx={{ alignSelf: "end" }} variant="contained" color="contrast" onClick={onSubmit}>Submit</Button>
        </Box>
    </Collapse>

}

const QuestionComment = ({ comment, editComment, deleteComment, canModify, members }: {
    editComment: (commentId: string, content: string) => void, deleteComment: (commentId: string) => void
    canModify: boolean, comment: Comment, members: Member[]
}) => {
    const memberInfo = getMemberInfoById(comment.user_id, members);
    const { anchorEl, handleClick, handleClose, open } = usePopover();
    const theme = useTheme();
    const hoverColor = theme.palette.action.hover;
    const [backgroundColor, setBackgroundColor] = useState("transparent");
    const [isEditing, setIsEditing] = useState(false);
    const isEdited = !!comment.updated_at;

    const location = useLocation();
    const commentId = location.state?.commentId;
    const isTarget = commentId === comment.id;
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isTarget) {
            ref.current?.scrollIntoView({ behavior: "auto" });
        }
    }, [isTarget]);

    const onMouseOver = (event: React.MouseEvent<HTMLElement>) => {
        if (canModify) {
            handleClick(event);
            setBackgroundColor(hoverColor);
        }
    }

    const onMouseLeave = () => {
        if (canModify) {
            handleClose();
            setBackgroundColor("transparent");
        }
    }

    const onEditSubmit = (content: string) => {
        editComment(comment.id, content);
        setIsEditing(false);
    }

    const onEditCancel = () => {
        setIsEditing(false);
    }

    const startEditing = () => {
        setIsEditing(true);
        handleClose();
        setBackgroundColor("transparent");
    }

    return <Box ref={ref} style={{scrollMarginTop: "16px", padding: "4px", backgroundColor: isTarget ? theme.palette.action.selected : backgroundColor }} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        {canModify && <Popper anchorEl={anchorEl} open={open} placement="top-end" modifiers={[{ name: "offset", options: { offset: [0, -16] } }]}>
            <Box style={{ backgroundColor: theme.palette.background.paper, border: "1px solid", borderColor: theme.palette.divider, borderRadius: "16px", paddingInline: "8px" }}>
                <IconButton size="small" onClick={startEditing}><EditOutlinedIcon /></IconButton>
                <IconButton size="small" onClick={() => deleteComment(comment.id)}><DeleteOutlineOutlinedIcon /></IconButton>
            </Box>
        </Popper>}
        <Box style={{ display: "flex", gap: "8px" }} >
            <HeaderAvatar memberInfo={memberInfo} />
            <Typography variant="body2" color="text.secondary">{`${datetimeStringToDatetimeReadableNoWeekday(comment.created_at)} ${datetimeStringToHourReadable(comment.created_at)}`}</Typography>
        </Box>
        {isEditing ?
            <CommentEditor initialValue={comment.content} onSubmit={onEditSubmit} onCancel={onEditCancel} />
            :
            <>
                <Typography style={{ wordWrap: "break-word" }} variant="body1">{comment.content}</Typography>
                {isEdited && <Typography variant="caption" color="text.secondary">{`Edited ${datetimeStringToHourReadable(comment.updated_at || "")}`}</Typography>}
            </>

        }
    </Box>
}

const CommentEditor = ({ initialValue = "", onSubmit, onCancel }: { initialValue?: string, onSubmit: (content: string) => void, onCancel?: () => void }) => {
    const [content, setContent] = useState(initialValue);
    const hasChanged = initialValue !== content;

    const onCommentSubmit = () => {
        if (!hasChanged) {
            return;
        }
        onSubmit(content);
        setContent("");
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onCommentSubmit();
        }
    }

    return <Box style={{ display: "flex", flexDirection: "row", gap: "16px", paddingTop: "16px", alignItems: "center" }}>
        <TextField onKeyDown={onKeyPress} fullWidth multiline label="Comment" value={content} onChange={(e) => setContent(e.target.value)} />
        {!!onCancel && <Button variant="outlined" color="contrast" onClick={onCancel}>Cancel</Button>}
        <Button variant="contained" color="contrast" disabled={!hasChanged} onClick={onCommentSubmit}>Submit</Button>
    </Box>
}

const DividerWithText = ({ text }: { text: string }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
            <Divider sx={{ flexGrow: 1 }} />
            <Typography sx={{ mx: 2, whiteSpace: 'nowrap' }}>{text}</Typography>
            <Divider sx={{ flexGrow: 1 }} />
        </Box>
    );
};

const QuestionComments = ({ comments, open, createComment, editComment, deleteComment, userId, isAuthenticated, members }: {
    comments: Comment[], open: boolean, createComment: (content: string) => void, members: Member[],
    editComment: (commentId: string, content: string) => void, deleteComment: (commentId: string) => void, userId?: string, isAuthenticated: boolean
}) => {
    const { isGuest } = useAuth();
    return <Collapse in={open}>
        <DividerWithText text="Comments" />
        <Box style={{ display: "flex", flexDirection: "column", "gap": "8px" }}>
            {comments.map((comment) => <QuestionComment key={comment.id} members={members} comment={comment} editComment={editComment} deleteComment={deleteComment} canModify={comment.user_id === userId} />)}
        </Box>
        {!isGuest && <CommentEditor onSubmit={createComment} />}
    </Collapse>

}

const QuestionFooter = ({ question, answerEmpty, isGenerating, sessionId, interactionId, members }: { question: InteractionEvent, answerEmpty: boolean, isGenerating: boolean, sessionId: string, interactionId: string, members: Member[] }) => {
    const [feedbackOpen, setFeedbackOpen] = useState(false);

    const location = useLocation();
    const interactionEventId = location.state?.interactionEventId;
    const commentId = location.state?.commentId;
    const [commentOpen, setCommentOpen] = useState(interactionEventId === question.id && !!commentId);
    const { user, isAuthenticated } = useAuth();
    const dispatch = useAppDispatch();
    const comments = useSelector(selectCommentsForInteractionEvent(sessionId, interactionId, question.id));
    const commentCount = comments.length;

    const createComment = useCallback((content: string) => {
        dispatch(fetchCreateInteractionEventComment({ sessionId, interactionId, interactionEventId: question.id, content: content }));
    }, [dispatch, sessionId, interactionId, question.id]);

    const editComment = useCallback((commentId: string, content: string) => {
        dispatch(fetchEditComment({ commentId, content: content }));
    }, [dispatch]);

    const deleteComment = useCallback((commentId: string) => {
        dispatch(fetchDeleteComment({ commentId }));
    }, [dispatch]);

    const isAuthor = question.user_id === user?.id;
    const isRegenerateImplented = false;
    const isShareImplemented = false;
    const allActions = <>
        {isRegenerateImplented && isAuthor && <IconButton color="inherit" size="small" onClick={() => { }}><RefreshOutlinedIcon fontSize="small" color="inherit" /></IconButton>}
        {isShareImplemented && <IconButton color="inherit" size="small" onClick={() => { }}><ShareOutlinedIcon fontSize="small" color="inherit" /></IconButton>}
        <IconButton aria-label="Feedback" color="inherit" size="small" onClick={() => setFeedbackOpen(!feedbackOpen)}><ThumbDownAltOutlinedIcon color="inherit" /></IconButton>
        <IconButton aria-label="Comments" color="inherit" size="small" onClick={() => setCommentOpen(!commentOpen)}>
            <Badge badgeContent={commentCount} color="primary" showZero >
                <CommentOutlinedIcon color="inherit" />
            </Badge>
        </IconButton>
    </>

    const stopIsImplemented = false;
    const generatingActions = <>
        {stopIsImplemented && <IconButton size="small" onClick={() => { }}><StopCircleOutlinedIcon fontSize="large" /></IconButton>}
    </>
    const alignSelf = isGenerating ? "center" : "end";

    const submitFeedback = (feedback: string[]) => {
        if (!answerEmpty) {
            dispatch(fetchAddInteractionEventFeedback({ sessionId, interactionId: interactionId, eventId: question.id, feedback }))
        }
    }


    return <ClickAwayListener onClickAway={() => setFeedbackOpen(false)}>
        <Box sx={{ display: 'flex', flexDirection: "column" }}>
            <Box sx={{ display: 'flex', gap: '8px', alignSelf: alignSelf }} >
                <Typography color="text.secondary">
                    {isGenerating ? generatingActions : !answerEmpty && allActions}
                </Typography>
            </Box>
            <QuestionFeedback open={feedbackOpen} onClose={() => setFeedbackOpen(false)} submitFeedback={submitFeedback} />
            <QuestionComments members={members} comments={comments} open={commentOpen} createComment={createComment} editComment={editComment} deleteComment={deleteComment} userId={user?.id} isAuthenticated={isAuthenticated} />
        </Box>
    </ClickAwayListener>
}

const QuestionHeader = ({ question, members }: { question: InteractionEvent, members: Member[] }) => {

    const memberInfo = getMemberInfoById(question.user_id, members);

    return <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Box sx={{ display: 'flex', gap: '8px', justifyContent: "space-between" }}>
            <HeaderAvatar memberInfo={memberInfo} />
        </Box>
        <Typography variant="h3" >{question.query}</Typography>
        <Typography variant="body2" color="text.secondary">{datetimeStringToHourReadable(question.datetime)}</Typography>
    </Box>
}

export const InteractionEventTextComponent = ({ text, hideRefs = false }: { text: string, hideRefs?: boolean }) => {
    const dispatch = useAppDispatch();

    const onRefClick = (contentReference: ContentReference) => {
        dispatch(setCurrentContentReferences([contentReference]));
    };

    return <Markdown remarkPlugins={remarkPlugins} rehypePlugins={rehypePlugins} className='markdown-body' components={{
        a(props) {
            const { children, href, node, ...rest } = props;
            if (children === "CONTENT_REF") {
                if (hideRefs) {
                    return <></>;
                }
                const _href = href ? href : "";
                const [contentId, chunkId] = _href.split(CONTENT_CHUNK_SEPARATOR);
                const contentReference = { contentId, chunkId };
                return <span content-id={contentId} chunk-id={chunkId} className="content-reference" onClick={() => onRefClick(contentReference)}><InfoOutlinedIcon /></span>;
            } else {
                return <a href={href} {...rest}>{children}</a>;
            }
        }
    }} children={preprocessLaTeX(text)}></Markdown>;
};

export const InteractionEventWithAvatarComponent = ({ avatar, text }: { avatar: React.ReactNode, text: string }) => {
    return <Box sx={{ position: 'relative' }}>
        <Box>
            {avatar}
        </Box>
        <Box>
            {<InteractionEventTextComponent text={text} />}
        </Box>
    </Box>;

};

const UnknownMemberAvatar = ({ props }: { props: AvatarProps }) => {
    return <Avatar {...props}>U</Avatar>;
}

