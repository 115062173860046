import { Typography } from "@mui/material";


export const PrivacyPolicy = () => {
    return <>
        <Typography variant="h1">Privacy Policy</Typography>
        <Typography variant="body1">
            This document describes how we handle personal data at Brua.io. It also contains contact information in case you want us to delete your data. It’s written with clarity in mind, so it should be easy to follow.
            At Brua.io we’re dedicated to keep your data safe, and we only collect information you explicitly share with us.
            If you have any other questions which aren’t answered in this document, don’t hesitate to reach out via email (support@brua.io), and we’ll get back to you as soon as possible.
        </Typography>
        <Typography variant="h2">Who is collecting the data?</Typography>
        <Typography variant="body1">
            The data is colleted at Brua.io, a website which is owned and operated by the Norwegian corporation Brua IO AS.
        </Typography>
        <Typography variant="h2">What data is being collected?</Typography>
        <Typography variant="body1">
            The only personal data we collect is the data you give to us. This includes your email address, the username you choose, and the first and last name you choose to share.
            We use Amazon Cognito to store your personal data. Amazon Cognito is a secure user directory that handles user authentication and authorization for your web and mobile apps. The data is stored in one of their EU regions.
            We use Sentry for error tracking and feedback. This data is pseudoanonymized. We’re not sending any personal information to Sentry, but we do send the IP address. The data is stored in their USA datacenter.
            We use Stripe for payment processing. This data is stored in their EU datacenter.
        </Typography>
        <Typography variant="h2">What is the legal basis for processing the data?</Typography>
        <Typography variant="body1">
            The legal basis for processing your personal data is your consent when signing up for Brua. The only personal data you share with us is the details you share in the signup process, on your profile and your payment details.
        </Typography>
        <Typography variant="h2">Will the data be shared with any third parties?</Typography>
        <Typography variant="body1">
            Our app data is stored on AWS EC2 on one of their EU regions.
        </Typography>
        <Typography variant="body1">
            Compressed backups of this data is stored in AWS S3.
        </Typography>
        <Typography variant="h2">How long will the data be stored for?</Typography>
        <Typography variant="body1">
            The data will be stored until the user asks us to delete it.
        </Typography>
        <Typography variant="h2">What rights does the data subject have?</Typography>
        <Typography variant="body1">
            You can at any given time ask us to delete your data, and we’ll fullfill your request without questions.
        </Typography>
        <Typography variant="h2">How can the data subject raise a complaint?</Typography>
        <Typography variant="body1">
            If you want us to delete your data please send an email to support@brua.io and we’ll delete it.
        </Typography>
    </>
}