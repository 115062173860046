import { Socket } from "../socket"
import { createAction, Middleware } from "@reduxjs/toolkit";
import { getUserSocketConfig } from "../api";
import { InteractionEvent, SessionAction, UserNotification } from "../types";
import { addInteractionEvent, addSessionAction } from "../features/session/sessionSlice";
import { addNotification } from "../features/userNotifications/userNotificationsSlice";

interface SessionActionMessage {
  type: 'session_action'
  payload: SessionAction
  session_id: string
}

interface NotificationMessage {
  type: 'notification'
  payload: UserNotification
}

interface InteractionEventMessage {
  type: 'interaction_event'
  payload: InteractionEvent
  session_id: string
  interaction_id: string
}

type SocketMessage = SessionActionMessage | NotificationMessage | InteractionEventMessage

export const connectToSocket = createAction('socket/connect')
export const disconnectFromSocket = createAction('socket/disconnect')
export const subscribeToSession = createAction<{ sessionId: string }>('socket/sessionSubscribe')
export const unsubscribeFromSession = createAction<{ sessionId: string }>('socket/sessionUnsubscribe')
export const subscribeToInteraction = createAction<{ sessionId: string, interactionId: string }>('socket/interactionSubscribe')
export const unsubscribeFromInteraction = createAction<{ sessionId: string, interactionId: string }>('socket/interactionUnsubscribe')

export const getSocketMiddleware = (socket: Socket) => {
  const socketMiddleware: Middleware = (params) => (next) => async (action) => {
    const { dispatch } = params
    // @ts-ignore
    const { type, payload } = action
    const { sessionId, interactionId } = payload || {}

    const onSessionAction = (message: SessionActionMessage) => {
      const payload = { sessionId: message.session_id, action: message.payload }
      // if (data.action_type === 'AddContentAction') {
      //   // @ts-ignore
      //   dispatch(fetchAndAddContent({ sessionId: message.session_id, contentId: data.updated_content_id }))
      // }
      dispatch(addSessionAction(payload));
    }

    const onNotification = (message: NotificationMessage) => {
      dispatch(addNotification(message.payload))
    }

    const onInteractionEvent = (message: InteractionEventMessage) => {
      const payload = { sessionId: message.session_id, interactionId: message.interaction_id, event: message.payload }
      dispatch(addInteractionEvent(payload));
    }

    const onMessage = (raw_message: any) => {
      const message = JSON.parse(raw_message.data) as SocketMessage
      if (message.type === 'session_action') {
        onSessionAction(message)
      } else if (message.type === 'notification') {
        onNotification(message)
      } else if (message.type === 'interaction_event') {
        onInteractionEvent(message)
      }
      else {
        console.error('Unknown message: ', message)
      }
    }

    switch (type) {
      case connectToSocket.type:
        const getConfig = async () => await getUserSocketConfig()
        await socket.connect(getConfig, "user", onMessage)
        break

      case disconnectFromSocket.type:
        socket.disconnect()
        break

      case subscribeToSession.type:
        socket.subscribeToSession({ sessionId });
        break
      case unsubscribeFromSession.type:
        socket.unsubscribeFromSession({ sessionId });
        break
      case subscribeToInteraction.type:
        socket.subscribeToInteraction({ sessionId, interactionId });
        break
      case unsubscribeFromInteraction.type:
        socket.unsubscribeFromInteraction({ sessionId, interactionId });
        break
      default:
        break
    }

    return next(action)
  }

  return socketMiddleware;
}