import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createInvitedUser, validateInvitation } from "../api";
import { Auth } from 'aws-amplify';
import { useAuth } from "../auth";
import AuthPage from "./auth";

const SignupFormField = (props: { label: string, placeholder: string, type: string, required: boolean, set: (value: string) => void, value: string, errorMessage: string }) => {
    const { label, placeholder, type, required, set, value, errorMessage } = props;
    const labelvariant = "body1";
    const isError = errorMessage !== " ";
    const isEmpty = value === "";
    return <Box className="signup-form-field">
        <Typography component="span" variant={labelvariant}>{label}</Typography>{required && isEmpty && <Typography component="span" variant={labelvariant} color="red"> *</Typography>}
        <TextField onChange={e => set(e.target.value)} error={isError} helperText={errorMessage} value={value} label="" variant="outlined" type={type} placeholder={placeholder} fullWidth />
    </Box>
}

type InviteIdState = "idle" | "loading" | "valid" | "invalid";

export const InvitedSignup = () => {

    const { inviteId } = useParams();

    const [inviteIdValid, setInviteIdValid] = useState<boolean>(false);
    const [inviteIdState, setInviteIdState] = useState<InviteIdState>("idle");

    useEffect(() => {
        if (inviteId && inviteIdState === "idle") {
            setInviteIdState("loading");
            validateInvitation(inviteId)
                .then((response) => {
                    setInviteIdValid(true); setInviteIdState("valid")
                })
                .catch((e) => {
                    setInviteIdValid(false); setInviteIdState("invalid")
                })
        }
    }, [inviteId, inviteIdState])

    const [email, setEmail] = useState<string>("");
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameValid, setFirstNameValid] = useState<boolean>(false);
    const [lastName, setLastName] = useState<string>("");
    const [lastNameValid, setLastNameValid] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [passwordValid, setPasswordValid] = useState<boolean>(false);
    const [repeatPassword, setRepeatPassword] = useState<string>("");
    const [repeatPasswordValid, setRepeatPasswordValid] = useState<boolean>(false);
    const [username, setUsername] = useState<string>("");
    const [usernameValid, setUsernameValid] = useState<boolean>(false);

    const [signupError, setSignupError] = useState<string>("");

    const emailErrorMessage = emailValid || email === "" ? " " : "Invalid email address";
    const firstNameErrorMessage = firstNameValid || firstName === "" ? " " : "Invalid name";
    const lastNameErrorMessage = lastNameValid || lastName === "" ? " " : "Invalid name";
    const usernameErrorMessage = usernameValid || username === "" ? " " : "Username can only contain letters, numbers, and dashes";
    const passwordErrorMessage = passwordValid || password === "" ? " " : "Invalid password";
    const repeatPasswordErrorMessage = repeatPasswordValid || repeatPassword === "" ? " " : "Passwords do not match";


    const { loading, isAuthenticated } = useAuth();

    if (loading) {
        return <></>
    }

    if (isAuthenticated) {
        return <Box>You already have an account.</Box>
    }

    const nameRegex = /^[\p{L} ]{1,128}$/u
    const usernameRegex = /^[a-zA-Z][a-zA-Z0-9-]{0,39}$/
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    const validateName = (name: string) => {
        return nameRegex.test(name)
    }

    const validateUsername = (username: string) => {
        return usernameRegex.test(username)
    }

    const validatePassword = (password: string) => {
        return password.length >= 10
    }

    const validateRepeatPassword = (repeatPassword: string) => {
        return password === repeatPassword
    }

    const validateEmail = (email: string) => {
        return emailRegex.test(email)
    }

    const doSetFirstName = (value: string) => {
        setFirstName(value);
        setFirstNameValid(validateName(value));
    }

    const doSetLastName = (value: string) => {
        setLastName(value);
        setLastNameValid(validateName(value));
    }

    const doSetUsername = (value: string) => {
        setUsername(value);
        setUsernameValid(validateUsername(value));
    }

    const doSetEmail = (value: string) => {
        setEmail(value);
        setEmailValid(validateEmail(value));
        setSignupError("");
    }

    const doSetPassword = (value: string) => {
        setPassword(value);
        setPasswordValid(validatePassword(value));
    }

    const doSetRepeatPassword = (value: string) => {
        setRepeatPassword(value);
        setRepeatPasswordValid(validateRepeatPassword(value));
    }

    const allValid = emailValid && firstNameValid && lastNameValid && passwordValid && repeatPasswordValid && usernameValid && inviteIdValid && inviteId;

    const doSignup = () => {
        if (allValid) {
            createInvitedUser(inviteId, email, username, password, firstName, lastName).then(() => {
                Auth.signIn(email, password).then((user) => {
                    Auth.verifyUserAttribute(user, "email").then(() => {
                        window.location.href = "/"
                    })
                })
            }).catch((e) => {
                setSignupError(e.response.data.detail);
            });
        }
    }

    if (inviteId && (inviteIdState === "loading" || inviteIdState === "idle")) {
        return <Box className="auth-form-wrapper">
            <Box className="signup-form">
                <CircularProgress />
            </Box>
        </Box>
    }
    if (inviteId === undefined || !inviteIdValid) {
        return <Box className="auth-form-wrapper">
            <Box className="signup-form">
                <Typography variant="h3">Brua IO</Typography>
                <Typography variant="body1">Invalid invitation link</Typography>
            </Box>
        </Box>
    }

    return <AuthPage>
        <Box className="auth-form-wrapper">
            <Box className="signup-form">
                <Typography variant="h2">Brua IO</Typography>
                <Box className="spacing-medium" />
                <SignupFormField label="Email" placeholder="Email" type="email" required={true} set={doSetEmail} value={email} errorMessage={emailErrorMessage} />
                <SignupFormField label="Username" placeholder="Username" type="text" required={true} set={doSetUsername} value={username} errorMessage={usernameErrorMessage} />
                <SignupFormField label="First Name" placeholder="First Name" type="text" required={true} set={doSetFirstName} value={firstName} errorMessage={firstNameErrorMessage} />
                <SignupFormField label="Last Name" placeholder="Last Name" type="text" required={true} set={doSetLastName} value={lastName} errorMessage={lastNameErrorMessage} />
                <SignupFormField label="Password" placeholder="" type="password" required={true} set={doSetPassword} value={password} errorMessage={passwordErrorMessage} />
                <SignupFormField label="Repeat Password" placeholder="" type="password" required={true} set={doSetRepeatPassword} value={repeatPassword} errorMessage={repeatPasswordErrorMessage} />
                <Box>
                    <Button size="large" variant="contained" fullWidth disabled={!allValid} onClick={() => doSignup()}>Sign up</Button>
                </Box>
                <Typography variant="body1" color="red" style={{ wordWrap: "break-word" }}>{signupError}</Typography>
            </Box>
        </Box>
    </AuthPage>
}