import { Middleware } from "@reduxjs/toolkit";
import { addSessionAction, fetchAndAddContent, fetchAndAddMember, fetchSession, removeContentFromSession, removeMemberFromSession } from "../features/session/sessionSlice";
import { SessionAction } from "../types";


export const sessionActionMiddleware: Middleware = (params) => (next) => async (action) => {
  const { dispatch } = params

  // @ts-ignore
  const { payload, type } = action

  if (type === addSessionAction.type) {
    const { sessionId, action: sessionAction } = payload as { sessionId: string, action: SessionAction }

    switch (sessionAction.action_type) {
      case "AddContentAction":
        if (sessionAction.added_content_id) {
          // @ts-ignore
          dispatch(fetchAndAddContent({ sessionId: sessionId, contentId: sessionAction.added_content_id }));
        }
        break;
      case "DeleteContentAction":
        if (sessionAction.deleted_content_id) {
          dispatch(removeContentFromSession({ sessionId: sessionId, contentId: sessionAction.deleted_content_id }));
        }
        break;
      case "AddMemberAction":
        if (sessionAction.added_member_id) {
          // @ts-ignore
          dispatch(fetchAndAddMember({ sessionId: sessionId, memberId: sessionAction.added_member_id }));
        }
        break;
      case "RemoveMemberAction":
        if (sessionAction.removed_member_id) {
          dispatch(removeMemberFromSession({ sessionId: sessionId, memberId: sessionAction.removed_member_id }));
        }
        break;
      case "LinkSessionAction":
        if (sessionAction.linked_session_id) {
          // @ts-ignore
          dispatch(fetchSession(sessionId));
        }
        break;
      case "UnlinkSessionAction":
        if (sessionAction.unlinked_session_id) {
          // @ts-ignore
          dispatch(fetchSession(sessionId));
        }
        break;
    }
  }

  return next(action)
}
