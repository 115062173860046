import { Box, IconButton, Typography, TypographyProps } from "@mui/material";
import { Modal, ModalButton, ModalContents } from "./modal";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

export const deleteTitle = "Are you sure?"
export const deleteAcceptText = "Yes, delete it"

export const ConfirmModalBody = (props: TypographyProps) => {
    return <Typography component="span" variant="h4" color="text.secondary" {...props} />
}

export const ConfirmModalStrong = (props: TypographyProps) => {
    return <Typography variant="h3" color="primary" {...props} component="span" />
}

interface ConfirmModalProps {
    title: string;
    body: React.ReactNode;
    open: boolean;
    onAccept: () => void;
    onClose: () => void;
    acceptText?: string;
    cancelText?: string;
    noCancel?: boolean;
    icon?: JSX.Element;
}

export const ConfirmModal = ({ title, body, acceptText = "Accept", cancelText = "Cancel", open, onClose, onAccept, noCancel = false, icon }: ConfirmModalProps) => {

    const doAccept = () => {
        onAccept();
        onClose();
    }

    return <Modal open={open} onClose={onClose} small>
        <Box className="modal-close-icon">
            <IconButton onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Box>
        <ModalContents>
            <Box className="confirm-modal-contents">
                {
                    icon &&
                    <Box className="confirm-modal-icon">
                        {React.cloneElement(icon, { sx: { fontSize: '2rem' } })}
                    </Box>
                }
                <Typography variant="h1">{title}</Typography>
                <Typography variant="body1">{body}</Typography>
            </Box>
            <Box className="confirm-modal-actions">
                {!noCancel ? <ModalButton onClick={onClose} variant="text" color="primary" size="large">{cancelText}</ModalButton> : <></>}
                <ModalButton onClick={doAccept} variant="contained" color="contrast" size="large">{acceptText}</ModalButton>
            </Box>
        </ModalContents>
    </Modal>;
};
