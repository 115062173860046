import { Box, Typography } from "@mui/material"
import AuthPage from "./auth";
import { Logo } from "../logo";
const PaymentFailurePageBody = () => {

    return <>
        <Box className="auth-action-panel-header">
            <Logo />
        </Box>

        <Box className="auth-error-wrapper">
            <Typography variant="h1">Something went wrong</Typography>
            <Box className="spacing-small" />
            <Typography variant="h3">Your payment failed</Typography>
        </Box>
    </>
}

export const PaymentFailurePage = () => {
    return <AuthPage>
        <PaymentFailurePageBody />
    </AuthPage>
}

