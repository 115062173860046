import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAuthenticator } from '@aws-amplify/ui-react';

import authPanelImage from '../images/auth_panel_background.png';

const AuthPageImagePanel = () => {
    return <Box className="auth-image-panel" sx={{ backgroundImage: `url(${authPanelImage})` }}>
        <Box className="auth-image-panel-text">
            <p>Share</p>
            <p>knowledge,</p>
            <p>simply and</p>
            <p>efficiently.</p>
            <div className="auth-image-panel-text-divider"></div>
            <p>Brua</p>
        </Box>
    </Box>
}

const AuthPage = ({ children }: { children: React.ReactNode; }) => {

    const navigate = useNavigate();

    const { route } = useAuthenticator(context => [context.route]);


    useEffect(() => {
        if (route === "verifyUser") {
            navigate("/verify", { replace: true });
        }
    }, [route, navigate])


    return (
        <Box className="auth-page">
            <Box className="auth-action-panel">
                {children}
            </Box>
            <AuthPageImagePanel />
        </Box>
    );
}

export default AuthPage;