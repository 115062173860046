import { Avatar, AvatarGroup, AvatarGroupProps, AvatarProps } from "@mui/material";
import { Interaction, Member, MemberRole, User } from "./types";

export interface MemberInfo {
    displayName: string,
    avatarUrl?: string,
    email: string,
    initials: string,
    username?: string
    member?: Member
}

const unknownMemberInfo: MemberInfo = { displayName: "Unknown", email: "Unknown", initials: "UK" }

export const getMemberInfo = (member?: Member): MemberInfo => {
    if (!member) {
        return unknownMemberInfo;
    }
    return { ...getMemberInfoFromUser(member.user), member: member };
}

export const getMemberInfoFromUser = (user: User): MemberInfo => {
    let displayName = ""
    if (user.first_name) {
        displayName += user.first_name;
    }
    if (user.last_name) {
        displayName += " " + user.last_name;
    }

    if (!displayName && user.username) {
        displayName += user.username;
    }

    if (!displayName) {
        displayName += user.email;
    }

    const initials = displayName.split(" ").map((name) => name[0]).join("").toUpperCase();
    const avatarUrl = user.image;

    return { displayName: displayName, email: user.email, initials: initials, avatarUrl: avatarUrl, username: user.username }
}
export const getMemberInfoById = (user_id: string | undefined, members: Member[]): MemberInfo | undefined => {
    if (!user_id) {
        return undefined;
    }
    const member = members.find(member => member.user.id === user_id);
    if (!member) {
        return undefined;
    }
    return getMemberInfo(member);
}

export const getMemberInfoAvatar = (memberInfo: MemberInfo, props?: AvatarProps): JSX.Element => {
    return <Avatar
        alt={memberInfo.displayName}
        src={memberInfo.avatarUrl}
        {...props}
        key={memberInfo.member?.user.id}>{memberInfo.initials}</Avatar>
}

export const getUnknownUserAvatar = (props?: AvatarProps): JSX.Element => {
    return <Avatar
        alt="Unknown"
        {...props}>UK</Avatar>
}

export const getMemberAvatar = (member: Member, props?: AvatarProps): JSX.Element => {
    const memberInfo = getMemberInfo(member);
    return getMemberInfoAvatar(memberInfo, props);
}

export const MembersAvatarGroup = ({ avatarProps, members, ...props }: AvatarGroupProps & { members: Member[], avatarProps?: AvatarProps }): JSX.Element => {
    return <AvatarGroup {...props}>
        {members.map((member) => getMemberAvatar(member, avatarProps))}
    </AvatarGroup>
}

export const getInteractionMembers = (interaction: Interaction, members: Member[]): Member[] => {
    return interaction.members.map(memberId => members.find(m => m.user.id === memberId)).filter((m): m is Member => m !== undefined);
}

export const getRoleLabel = (role: MemberRole) => {
    switch (role) {
        case "ADMIN":
            return "Admin";
        case "CONTRIBUTOR":
            return "Contributor";
        case "VIEWER":
            return "Viewer";
    }
}

export const getCompareMembers = (creatorUserId?: string) => (a: Member, b: Member): number => {
    if (a.user.id === creatorUserId) {
        return -1;
    }
    if (b.user.id === creatorUserId) {
        return 1;
    }
    if (a.role === "ADMIN") {
        return -1;
    }
    if (b.role === "ADMIN") {
        return 1;
    }
    if (a.role === "CONTRIBUTOR") {
        return -1;
    }
    if (b.role === "CONTRIBUTOR") {
        return 1;
    }
    return 0;
}

export const sortMembers = (members: Member[], creatorUserId?: string): Member[] => {
    return [...members].sort(getCompareMembers(creatorUserId));
}