import React from "react";

export const usePopover = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const setTarget = (target: HTMLElement) => {
        setAnchorEl(target);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = Boolean(anchorEl);

    return { anchorEl, handleClick, setTarget, handleClose, open};
}