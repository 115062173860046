import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchUserNotifications, selectUserNotificationsState } from "./userNotificationsSlice";
import { useAuth } from "../../auth";

export default function UserNotificationsLoaderComponent() {

    const { isAuthenticated } = useAuth();
    const notificationsLoadedState = useAppSelector(selectUserNotificationsState);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isAuthenticated && notificationsLoadedState === "idle") {
            dispatch(fetchUserNotifications());
        }
    }, [dispatch, notificationsLoadedState, isAuthenticated]);

    return null;
}