import { Box, CircularProgress, Divider, MenuItem, SxProps, Table, TableBody, TableCell, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme, } from "@mui/material"
import { Content, Session, Source } from "../types";
import { SourceCardLargeComponent, SourceMedia } from "../components/cards/sourceCard";
import { DrawerContentSourceDetails, DrawerLinkedSessionSourceDetails } from "../components/sourcesPageDrawer";
import { SessionPageFooter, UploadProviders } from "./session";
import { useNavigate, useParams } from "react-router-dom";
import { useSourcesPath } from "../hooks/useUrl";
import { useAppSelector } from "../app/hooks";
import { selectSession, selectSessionsById } from "../features/session/sessionSlice";
import { Modal } from "../components/modals/modal";
import { useAuth } from "../auth";
import { userIsSessionContributorOrAdmin } from "../sessionUtils";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import React, { useMemo } from "react";
import { dateToDatetimeReadableNoWeekday } from "../time";
import { InternalPage, InternalPageContentBodyHeader } from "./internalPage";
import { useSession } from "../hooks/useSession";
import { SessionConnector } from "../connectors/sessionConnector";
import { sourceFromContent, sourceFromLinkedSession } from "../features/source";

type SourcesFilterOptions = "all" | "content" | "spaces";
type SourcesSortOptions = "popular" | "newest" | "oldest";
type SourcesDisplayType = "grid" | "table";


const SourcesDisplay = ({ sources, onContentClick, onLinkedSessionClick, displayType }: { sources: Source[], displayType: SourcesDisplayType, onContentClick: (content: Content) => void, onLinkedSessionClick: (session: Session) => void }) => {

    const onSourceClick = (source: Source) => {
        if (source.type === "LINKED_SESSION") {
            onLinkedSessionClick(source.base as Session)
        } else {
            onContentClick(source.base as Content)
        }
    }
    return <>
        {
            displayType === "grid" ? <SourcesGrid sources={sources} onSourceClick={onSourceClick} /> : <SourcesTable sources={sources} onSourceClick={onSourceClick} />
        }
    </>
}


const SourcesGrid = ({ sources, onSourceClick }: { sources: Source[], onSourceClick: (source: Source) => void }) => {
    const [filter, setFilter] = React.useState<SourcesFilterOptions>("all");
    const [sort, setSort] = React.useState<SourcesSortOptions>("newest");

    const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value as SourcesFilterOptions);
    }
    const onChangeSort = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSort(event.target.value as SourcesSortOptions);
    }

    const filteredSources = useMemo(() => {
        if (filter === "all") {
            return sources;
        } else if (filter === "content") {
            return sources.filter(source => source.type !== "LINKED_SESSION");
        } else if (filter === "spaces") {
            return sources.filter(source => source.type === "LINKED_SESSION");
        } else {
            return sources;
        }
    }, [sources, filter])

    const sortedSources = useMemo(() => {
        return [...filteredSources].sort((a, b) => {
            if (sort === "popular") {
                return b.references - a.references
            } else if (sort === "newest") {
                return b.addedTime.getTime() - a.addedTime.getTime();
            } else if (sort === "oldest") {
                return a.addedTime.getTime() - b.addedTime.getTime();
            } else {
                return 0;
            }
        })
    }, [filteredSources, sort])

    return <Box className="sources-page-content">
        <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            <TextField label="" select onChange={onChangeFilter} size="small" value={filter}>
                <MenuItem value="all">All sources</MenuItem>
                <MenuItem value="content">Content</MenuItem>
                <MenuItem value="spaces">Linked spaces</MenuItem>
            </TextField>
            <TextField label="" select onChange={onChangeSort} size="small" value={sort}>
                <MenuItem value="popular">Popular</MenuItem>
                <MenuItem value="newest">Newest</MenuItem>
                <MenuItem value="oldest">Oldest</MenuItem>
            </TextField>
        </Box >

        <Box className="sources-page-content-scrollable-child hide-scrollbar sources-grid">
            {sortedSources.map((source) => <SourceCardLargeComponent key={source.id} source={source} onClick={() => onSourceClick(source)} />)}
        </Box>
    </Box>
}

const SourcesTable = ({ sources, onSourceClick }: { sources: Source[], onSourceClick: (source: Source) => void }) => {
    return <Box className="sources-page-content">
        <Box className="sources-page-content-scrollable-child hide-scrollbar">
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Added by</TableCell>
                        <TableCell>Added date</TableCell>
                        <TableCell>References</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        sources.map((source) => {
                            const sourceMediaSx = { objectFit: 'cover', width: '72px', height: '54px' } as SxProps
                            const typeIcon = source.type === "LINKED_SESSION" ? <PublicOutlinedIcon /> : <InsertDriveFileOutlinedIcon />
                            return <TableRow key={source.id} onClick={() => onSourceClick(source)} className="sources-page-table-row">
                                <TableCell>
                                    <Box className="sources-page-table-cell-inline">
                                        <SourceMedia sx={sourceMediaSx} image={source.image} />{source.title}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box className="sources-page-table-cell-inline">
                                        {typeIcon}{source.type.toLowerCase()}
                                    </Box>
                                </TableCell>
                                {/* <TableCell>{getContentSourceShort(content)}</TableCell> */}
                                <TableCell>{source.addedBy.displayName}</TableCell>
                                <TableCell>{dateToDatetimeReadableNoWeekday(source.addedTime)}</TableCell>
                                <TableCell>{source.references}</TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </Box>
    </Box>
}


export const SessionPageSourcesTabFooter = ({ session }: { session: Session }) => {
    return <>
        <Divider />
        <SessionPageFooter session={session} emptyOnly displayOnDesktop />
    </>
}

export const SessionPageSourcesTabDrawer = ({ session }: { session: Session }) => {

    const { sourceId } = useParams();
    const navigate = useNavigate();

    const { user } = useAuth();
    const userCanEditSource = !!session && !!user && userIsSessionContributorOrAdmin(session, user?.id)

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const sourcesUrl = useSourcesPath();

    const candiateSelectedLinkedSession = useAppSelector(selectSession(sourceId));
    const selectedLinkedSession = session?.linked_sessions.includes(candiateSelectedLinkedSession?.id || "") ? candiateSelectedLinkedSession : undefined;
    const selectedContent = session?.contents.find(content => content.id === sourceId);

    const hasSelectedSource = !!(selectedLinkedSession || selectedContent);

    const onExit = () => {
        navigate(sourcesUrl())
    }

    let drawerSourceDetails = <></>;
    if (session) {
        if (selectedLinkedSession) {
            drawerSourceDetails = <DrawerLinkedSessionSourceDetails canEdit={userCanEditSource} linkedSession={selectedLinkedSession} session={session} onExit={onExit} />
        } else if (selectedContent) {
            drawerSourceDetails = <DrawerContentSourceDetails canEdit={userCanEditSource} content={selectedContent} onExit={onExit} session={session} />
        }
    }
    const drawer = <>
        {
            session && hasSelectedSource &&
            <Box className="hide-scrollbar mobile-only-flex sources-page-mobile-content">
                {drawerSourceDetails}
            </Box >
        }
        <Divider orientation="vertical" className="desktop-divider" />
        {
            session && hasSelectedSource &&
            <Box className="internal-page-drawer sources-page-drawer hide-scrollbar">
                {drawerSourceDetails}
            </Box>
        }</>
    return <>
        {
            isMobile ?
                <Modal open={hasSelectedSource} onClose={onExit}>
                    {drawer}
                </Modal>
                :
                drawer
        }
    </>
}

const SourcesPageHeader = ({ session, displayType, onDisplayTypeChange }: { session: Session, displayType: SourcesDisplayType, onDisplayTypeChange: (displayType: SourcesDisplayType) => void }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const onChangeDisplayType = (event: React.MouseEvent<HTMLElement>, newDisplayType: SourcesDisplayType) => {
        onDisplayTypeChange(newDisplayType);
    }

    return <InternalPageContentBodyHeader >
        {
            !isMobile &&
            <ToggleButtonGroup value={displayType} aria-label="display type" onChange={onChangeDisplayType} exclusive>
                <ToggleButton value="grid" aria-label="grid">
                    <ViewCompactIcon />
                </ToggleButton>
                <ToggleButton value="table" aria-label="table">
                    <ListOutlinedIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        }
    </InternalPageContentBodyHeader >
}

export const useSources = (session?: Session) => {
    const linkedSessionIds = session?.linked_sessions || [];
    const linkedSessions = useAppSelector(selectSessionsById(linkedSessionIds));
    const linkedSessionSources = useMemo(() => {
        if (!session) {
            return [];
        }
        return linkedSessions.map((linkedSession) => sourceFromLinkedSession(linkedSession, session))
    }, [linkedSessions, session])
    const contentSources = useMemo(() => {
        if (!session) {
            return [];
        }
        return session.contents.map((content) => sourceFromContent(content, session))
    }, [session])
    const sources = useMemo(() => [...linkedSessionSources, ...contentSources], [linkedSessionSources, contentSources])
    return sources;
}

const SourcesPageBody = () => {
    const [displayType, setDisplayType] = React.useState<SourcesDisplayType>("grid");

    const onDisplayTypeChange = (newDisplayType: SourcesDisplayType) => {
        setDisplayType(newDisplayType);
    }

    const { session, state } = useSession();
    const sources = useSources(session);
    const navigate = useNavigate();

    const sourcesUrl = useSourcesPath();
    const onContentClick = (content: Content) => {
        navigate(sourcesUrl(content.id))
    }

    const onLinkedSessionClick = (session: Session) => {
        navigate(sourcesUrl(session.id))
    }

    if (state === 'failed') {
        return <Typography variant="h1">Sorry, this space does not exist.</Typography>
    }

    if (state === 'loading') {
        return <CircularProgress />
    }

    return <>
        {session?.id && <SessionConnector sessionId={session?.id} sessionIsLoaded={!!session} />}
        <Box className="internal-page-center">
            {session && <SourcesPageHeader session={session} displayType={displayType} onDisplayTypeChange={onDisplayTypeChange} />}
            {session && <SourcesDisplay sources={sources} onContentClick={onContentClick} onLinkedSessionClick={onLinkedSessionClick} displayType={displayType} />}
            {session && <SessionPageSourcesTabFooter session={session} />}
        </Box>
        <Divider orientation="vertical" className="desktop-divider" />
        {session && <SessionPageSourcesTabDrawer session={session} />}
    </>
}

export const SourcesPage = () => {
    return <InternalPage>
        <UploadProviders>
            <SourcesPageBody />
        </UploadProviders>
    </InternalPage>
};