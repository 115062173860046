
import { fetchMarkAllNotificationAsSeen, fetchMarkNotificationAsSeen, selectUserNotifications } from "../features/userNotifications/userNotificationsSlice";
import { usePopover } from "../hooks/usePopover";
import { selectCommentById } from "../features/comments/commentsSlice";
import { dateTimeStringToReadableTimeSince } from "../time";
import { usePersistentState } from "../hooks/useStickyState";
import { Box, Typography, Badge, ListItemButton, ListItemText, PopoverProps, Popover, FormControlLabel, Switch, Divider, List, ListItem, Button, IconButton } from "@mui/material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectSessionById, selectInteraction } from "../features/session/sessionSlice";
import { NotificationType, Interaction, UserNotification, Session } from "../types";
import { DoneAllOutlined, NotificationsOutlined } from "@mui/icons-material";

const notificationTitle = (type: NotificationType, session?: Session, interaction?: Interaction) => {
    switch (type) {
        case "SESSION_MEMBER_ADDED":
            return <Box>
                <Typography
                    component="span"
                    variant="body2"
                >
                    {"Added to "}
                </Typography>
                {session?.name ? <Typography
                    component="span"
                    variant="body2"
                    sx={{ fontWeight: "bold", fontStyle: 'italic' }}
                >
                    {session.name}
                </Typography> : <Typography
                    component="span"
                    variant="body2"
                >
                    space
                </Typography>}
            </Box>
        case "SESSION_MEMBER_REMOVED":
            return "You were removed from a space"
        case "INTERACTION_MEMBER_ADDED":
            return <Box>
                <Typography
                    component="span"
                    variant="body2"
                >
                    {"Added to "}
                </Typography>
                {interaction?.name ? <Typography
                    component="span"
                    variant="body2"
                    sx={{ fontWeight: "bold", fontStyle: 'italic' }}
                >
                    {interaction?.name}
                </Typography>
                    : <Typography
                        component="span"
                        variant="body2">
                        interaction
                    </Typography>
                }
            </Box>
        case "NEW_MESSAGE":
            return "New message"
        case "INTERACTION_EVENT_COMMENT_CREATED":
            return <Box>
                <Typography
                    component="span"
                    variant="body2"
                >
                    {"New comment in "}
                </Typography>
                <Typography
                    component="span"
                    variant="body2"
                    sx={{ fontWeight: "bold", fontStyle: 'italic' }}
                >
                    {interaction?.name}
                </Typography>
            </Box>
        case "INTERACTION_COMMENT_CREATED":
            return "New comment"
        default:
            return "Unknown"
    }
}

const UserNotificationComponent = ({ notification }: { notification: UserNotification }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { session_id, interaction_id, interaction_event_id: interactionEventId, comment_id: commentId } = notification;

    const comment = useAppSelector(selectCommentById(commentId));
    const session = useAppSelector(selectSessionById(session_id));
    const interaction = useAppSelector(selectInteraction(session_id, interaction_id));

    let target = `/${session_id}`;
    if (interaction_id) {
        target = `${target}/${interaction_id}`;
    }

    const onClick = () => {
        if (!notification.seen) {
            dispatch(fetchMarkNotificationAsSeen({ notificationId: notification.id }));
        }
        navigate(target, { state: { commentId, interactionEventId } });
    }

    const title = notificationTitle(notification.event_type, session, interaction);
    const timeText = dateTimeStringToReadableTimeSince(notification.timestamp);
    const message = <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "8px" }}>
        {title}
        <Badge variant="dot" color="error" invisible={notification.seen} >
            <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.secondary', display: 'inline', flexShrink: 0 }}
            >
                {`${timeText} ago`}
            </Typography>
        </Badge>
    </Box>
    const secondary = comment?.content || "";
    return <ListItemButton onClick={onClick}>
        <ListItemText primary={message} secondary={secondary} secondaryTypographyProps={{ maxHeight: "1lh", overflow: "hidden" }} />
    </ListItemButton>
}

const UserNotificationsPopover = ({ notifications, hasUnreadNotifications, ...props }: PopoverProps & { notifications: UserNotification[], hasUnreadNotifications: boolean }) => {
    const showOnlyUnreadStorageKey = "notifications.showOnlyUnread";
    const [showOnlyUnread, setShowOnlyUnread] = usePersistentState(showOnlyUnreadStorageKey, false);
    const filteredNotifications = useMemo(() => showOnlyUnread ? notifications.filter(notification => !notification.seen) : notifications, [notifications, showOnlyUnread]);

    const passedProps = {
        ...props,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }
    } as const;

    const dispatch = useAppDispatch();

    const onClearUnreadClicked = () => {
        dispatch(fetchMarkAllNotificationAsSeen());
    }

    const sortedNotificationsByTimestamp = useMemo(() => [...filteredNotifications].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()), [filteredNotifications]);

    return <Popover {...passedProps} >
        <Box sx={{ paddingBlock: "8px", paddingInline: "12px", width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "row" }} >
            <Typography variant="subtitle1" sx={{ padding: "8px" }}>Notifications</Typography>
            <FormControlLabel
                control={<Switch checked={showOnlyUnread} color="warning" onChange={() => setShowOnlyUnread(!showOnlyUnread)} />}
                label="Unreads"
                labelPlacement="start"
            />
        </Box>
        <Divider />
        <List sx={{ maxHeight: "400px", overflow: "auto", width: "300px" }} className="hide-scrollbar" dense>
            {
                sortedNotificationsByTimestamp.map((notification) => {
                    return <UserNotificationComponent key={notification.id} notification={notification} />
                })
            }
            {
                sortedNotificationsByTimestamp.length === 0 &&
                <ListItem>
                    <ListItemText primary={`No${showOnlyUnread ? " unread" : ""} notifications`} />
                </ListItem>
            }
        </List>
        <Divider />
        <Button onClick={onClearUnreadClicked} sx={{ padding: "16px", borderRadius: 0 }} startIcon={<DoneAllOutlined />} fullWidth disabled={!hasUnreadNotifications}>
            Mark all as read
        </Button>
    </Popover>
}

export const UserNotificationsComponent = () => {
    const notifications = useAppSelector(selectUserNotifications);
    const unreadNotifications = useMemo(() => notifications.filter(notification => !notification.seen), [notifications]);
    const unreadNotificationsCount = unreadNotifications.length;
    const hasUnreadNotifications = unreadNotificationsCount > 0;

    const { anchorEl, handleClick, handleClose, open } = usePopover();

    const onNotificationButtonClicked = (event: React.MouseEvent<HTMLElement>) => {
        handleClick(event);
    }

    return <>
        <UserNotificationsPopover notifications={notifications} open={open} anchorEl={anchorEl} onClose={handleClose} hasUnreadNotifications={hasUnreadNotifications} />
        <IconButton onClick={onNotificationButtonClicked}>
            <Badge badgeContent={unreadNotificationsCount} color="error">
                <NotificationsOutlined fontSize="small" />
            </Badge>
        </IconButton>
    </>
}
