import { Typography, TypographyProps, styled } from "@mui/material";
import React from "react";
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';


const BubbleComponentNoBorder = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(0.5),
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    borderRadius: theme.spacing(3),
}));
const BubbleComponent = styled(BubbleComponentNoBorder)(({ theme }) => ({
    border: `1px solid ${theme.palette.border.main}`,
}));

export const HeaderPublicBubble = () => {
    return <Bubble text="Public" typographyProps={{variant: 'body1', color: 'primary'}} icon={<PublicOutlinedIcon />} noBorder />
}
export const HeaderPrivateBubble = () => {
    return <Bubble text="Private" icon={<LockOutlinedIcon />} noBorder />
}
export const CardPublicBubble = () => {
    return <Bubble text="Public" icon={<PublicOutlinedIcon />} noBorder />
}
export const CardPrivateBubble = () => {
    return <Bubble text="Private" icon={<LockOutlinedIcon fontSize="tiny" />} noBorder />
}

export const Bubble = ({ text, icon, typographyProps, noBorder = false }: { text: string, icon?: JSX.Element, noBorder?: boolean, typographyProps?: TypographyProps }) => {
    const Component = noBorder ? BubbleComponentNoBorder : BubbleComponent;

    const defaultTypographyProps: TypographyProps = {
        variant: "body2",
        color: "text.secondary"
    }

    const _typographyProps = { ...defaultTypographyProps, ...typographyProps }

    return <Component color={_typographyProps.color as string}>
        {
            icon
        }
        <Typography {..._typographyProps} >
            {text}
        </Typography>
    </Component>
}

