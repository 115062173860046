import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import * as Sentry from "@sentry/react";
import { feedback } from '../../App';

interface RequireAuthProps {
    children: JSX.Element;
    allowUnverified?: boolean;
}

export function RequireAuth({ children, allowUnverified }: RequireAuthProps): JSX.Element {
    const location = useLocation();

    const { loading, isAuthenticated, isVerified, user, isGuest } = useAuth();

    useEffect(() => {
        if (user) {
            Sentry.setUser({
                id: user.id,
            })
        }
    }, [user]);

    const onlyVerified = !allowUnverified

    const isAuth = !(loading || !isAuthenticated || (onlyVerified && !isVerified));

    useEffect(() => {
        if (isAuth) {
            const widget = feedback.getWidget()
            if (!widget) {
                feedback.createWidget();
            }
        }
    }, [isAuth]);

    if (loading) {
        return <></>
    }

    if (!isAuthenticated && isGuest) {
        return <Navigate to="/signup" state={{ from: location }} replace />;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!isVerified && onlyVerified) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <>
        {children}
    </>
}