import { Interaction, Session } from "./types";

export const lastTimeUserUsedInteraction = (interaction: Interaction, userId: string) => {
    const created_time = interaction.created_time;

    if (!interaction.events) {
        return created_time;
    }
    const userEvents = interaction.events.filter((event) => event.user_id === userId);
    if (userEvents.length === 0) {
        return created_time;
    }
    const lastUserEvent = userEvents[userEvents.length - 1];

    return lastUserEvent.datetime;
}


export const getInteractionsSortedByEvents = (sessions?: Session[], userId?: string) => {
    if (!sessions || !userId) {
        return [];
    }
    const insteractionsList = sessions?.map((session) => session.interactions.map((interaction) => ({ session, interaction, lastTime: lastTimeUserUsedInteraction(interaction, userId ) })))
    const flatInteractionsList =  insteractionsList?.flat()
    flatInteractionsList?.sort((a, b) => b.lastTime.localeCompare(a.lastTime))
    return flatInteractionsList
}