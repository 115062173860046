import Cookie from 'js-cookie'
import { GuestSessionState } from './types';
import { deleteGuestUser } from './api';

export const GUEST_AUTH_COOKIE_NAME = 'guest_session';
export const GUEST_STATE_COOKIE_NAME = 'guest_session_state';

export const guestSessionCookieExists = () => {
    const cookieValue = Cookie.get(GUEST_AUTH_COOKIE_NAME);
    return cookieValue !== undefined;
}

export const deleteGuestSessionCookie = () => {
    return Cookie.remove(GUEST_AUTH_COOKIE_NAME);
}

export const deleteGuestUserIfExists = async () => {
    if (guestSessionCookieExists()) {
        try {
            return await deleteGuestUser();
        } catch (e) {
            console.error("Failed to delete guest user", e);
            deleteGuestSessionCookie();
        }
    }
}

export const getGuestSessionState = () => {
    const cookieString = Cookie.get(GUEST_STATE_COOKIE_NAME);

    if (!cookieString) {
        throw new Error("Guest session state cookie not found");
    }

    const [first_name, last_name, username, user_id] = cookieString.split('|');

    return { first_name, last_name, username, id: user_id} as GuestSessionState;
}