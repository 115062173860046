import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { useAuth } from "../../auth";
import { fetchCommentsForInteraction } from "./commentsSlice";

export default function InteractionCommentsLoader({ interactionId, sessionId }: { interactionId: string, sessionId: string }) {

    const { isAuthenticated } = useAuth();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCommentsForInteraction({ sessionId: sessionId, interactionId: interactionId }));
        }
    }, [dispatch, interactionId, isAuthenticated, sessionId]);

    return null;
}