import _urlRegex from "url-regex-safe";
import _normalizeUrl from 'normalize-url';

export const urlRegex = _urlRegex({ exact: true });

const matchesUrlObject = (text: string) => {
    try {
        new URL(text);
        return true;
    } catch (_) {
        return false;
    }
}

export const isUrl = (text: string) => {
    const matchesUrlRegex = urlRegex.test(text);
    const _matchesUrlObject = matchesUrlObject(text);
    return matchesUrlRegex || _matchesUrlObject;
}

export const normalizeUrl = (text: string) => _normalizeUrl(text, { defaultProtocol: 'https', stripWWW: false, removeQueryParameters: false });