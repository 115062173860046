import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { subscribeToSession, unsubscribeFromSession } from "../middleware/socket";


export function SessionConnector({ sessionId, sessionIsLoaded }: { sessionId: string, sessionIsLoaded: boolean }) {

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (sessionIsLoaded && sessionId)
            dispatch(subscribeToSession({ sessionId: sessionId }));

        return () => {
            if (sessionId) {
                dispatch(unsubscribeFromSession({ sessionId: sessionId }));
            }
        }
    }, [dispatch, sessionId, sessionIsLoaded]);

    return <></>
}