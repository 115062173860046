
export const getClipboardText = async () => {
    if (!navigator.clipboard) {
        return "";
    }
    try {
        const clipboard = await navigator.clipboard.read();
        for (const clip of clipboard) {
            if (clip.types.includes("text/plain")) {
                const textBlob = await clip.getType("text/plain")
                return await textBlob.text();
            }
        }
    } catch (error) {
        
    }
    return "";
}

export const setClipboardText = async (text: string) => {
    if (!navigator.clipboard) {
        return;
    }
    await navigator.clipboard.write([
        new ClipboardItem({ "text/plain": new Blob([text], { type: "text/plain" }) })
    ]);
}