import { Divider, List, ListItemButton, ListItemIcon, ListItemText, Popover, PopoverProps } from "@mui/material"
import React from "react"


export const ActionsPopoverItem = ({ icon, text, onClick, color, disabled}: { icon?: JSX.Element, text: string, onClick?: () => void, color?: string, disabled?: boolean}) => {
    return <ListItemButton onClick={onClick} disabled={disabled}>
        {icon &&
            <ListItemIcon>
                {React.cloneElement(icon, { fontSize: 'small' })}
            </ListItemIcon>
        }
        <ListItemText primaryTypographyProps={{ variant: 'body1', color: color}}>
            {text}
        </ListItemText>
    </ListItemButton>

}

export const ActionsPopover = ({ footerAction, children, ...props }: PopoverProps & { footerAction?: React.ReactNode }) => {
    return <Popover {...props}>
        <List sx={{ padding: 0 }}>
            {children}
            {
                footerAction &&
                <>
                    <Divider />
                    {footerAction}
                </>
            }
        </List>
    </Popover>
}
