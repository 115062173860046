const getInteractionSubscribeMessage = ({ sessionId, interactionId }: InteractionSubscription) => {
  return JSON.stringify({
    "type": "subscribe_to_interaction",
    "session_id": sessionId,
    "interaction_id": interactionId
  })
}

const getInteractionUnsubscribeMessage = ({ sessionId, interactionId }: InteractionSubscription) => {
  return JSON.stringify({
    "type": "unsubscribe_from_interaction",
    "session_id": sessionId,
    "interaction_id": interactionId
  })
}

const getSessionSubscribeMessage = ({ sessionId }: SessionSubscription) => {
  return JSON.stringify({
    "type": "subscribe_to_session",
    "session_id": sessionId,
  })
}

const getSessionUnsubscribeMessage = ({ sessionId }: SessionSubscription) => {
  return JSON.stringify({
    "type": "unsubscribe_from_session",
    "session_id": sessionId,
  })
}

export class SocketConfig {
  protocol: string
  domain: string
  path: string
  token?: string

  constructor(protocol: string, domain: string, path: string, token?: string) {
    this.protocol = protocol
    this.domain = domain
    this.path = path
    this.token = token
  }

  get url() {
    const _url = `${this.protocol}://${this.domain}${this.path}`
    if (this.token) {
      return `${_url}?token=${this.token}`
    }
    return _url
  }
}

interface InteractionSubscription {
  sessionId: string
  interactionId: string
}

interface SessionSubscription {
  sessionId: string
}

class Socket {
  socket: WebSocket | null
  _id: string | null
  shouldReconnect: boolean
  _getConfig: (() => Promise<SocketConfig>)
  _onMessage = (message: any) => { }
  watchDog: NodeJS.Timeout | null
  interactionSubscription: InteractionSubscription | null;
  sessionSubscription: SessionSubscription | null;

  constructor() {
    this.socket = null;
    this._id = null;
    this.shouldReconnect = false;
    this._getConfig = () => Promise.resolve(new SocketConfig('', '', '', ''));
    this._onMessage = (message: any) => { };
    this.watchDog = null;
    this.interactionSubscription = null;
    this.sessionSubscription = null;
  }

  async connect(getConfig: () => Promise<SocketConfig>, id: string, onMessage: (message: any) => void) {
    if (id !== this._id && this.socket) {
      this.disconnect()
    }
    this.shouldReconnect = true;
    this._getConfig = getConfig
    this._onMessage = onMessage
    this.watchDog = setInterval(() => { this.reconnect() }, 5000)

    if (id !== this._id) {
      await this.internalConnect();
    } else {
    }
  }

  async reconnect() {
    const isClosed = !this.isNotClosed()
    if (this.shouldReconnect && isClosed) {
      await this.internalConnect(true)
    }
  }

  async internalConnect(isReconnect: boolean = false) {
    const config = await this._getConfig()
    this.socket = new WebSocket(config.url);
    this.socket.addEventListener('close', this.__onClose);
    this.socket.addEventListener('message', this._onMessage);

    if (isReconnect) {
      this.socket.addEventListener('open', () => {
        if (this.interactionSubscription) {
          this._subscribeToInteraction(this.interactionSubscription)
        }
        if (this.sessionSubscription) {
          this._subscribeToSession(this.sessionSubscription)
        }
      })
    }
  }

  disconnect() {
    this.shouldReconnect = false

    if (this.socket) {
      this.socket.close();
    }

    this.socket = null;
    this._id = null;
    this._getConfig = () => Promise.resolve(new SocketConfig('', '', '', ''))
    this._onMessage = (message: any) => { }
    clearInterval(this.watchDog!);
    this.watchDog = null;
    this.interactionSubscription = null;
    this.sessionSubscription = null;
  }

  send(message: string) {
    if (this.socket) {
      this.socket.send(message)
    }
  }

  _isSubscribedToInteraction(subscription: InteractionSubscription) {
    return this.interactionSubscription?.interactionId === subscription.interactionId && this.interactionSubscription?.sessionId === subscription.sessionId
  }

  _isSubscribedToSession(subscription: SessionSubscription) {
    return this.sessionSubscription?.sessionId === subscription.sessionId
  }

  _subscribeToInteraction(subscription: InteractionSubscription) {
    const message = getInteractionSubscribeMessage(subscription)
    this.send(message)
  }

  subscribeToInteraction(subscription: InteractionSubscription) {
    if (!this._isSubscribedToInteraction(subscription)) {
      this.interactionSubscription = { ...subscription }
      this._subscribeToInteraction(subscription)
    }
  }

  unsubscribeFromInteraction(subscription: InteractionSubscription) {
    if (this._isSubscribedToInteraction(subscription)) {
      this.interactionSubscription = null;
      this.send(getInteractionUnsubscribeMessage(subscription))
    }
  }

  _subscribeToSession(subscription: SessionSubscription) {
    const message = getSessionSubscribeMessage(subscription)
    this.send(message)
  }

  subscribeToSession(subscription: SessionSubscription) {
    if (!this._isSubscribedToSession(subscription)) {
      this.sessionSubscription = { ...subscription }
      this._subscribeToSession(subscription)
    }
  }

  unsubscribeFromSession(subscription: SessionSubscription) {
    if (this._isSubscribedToSession(subscription)) {
      this.sessionSubscription = null;
      this.send(getSessionUnsubscribeMessage(subscription))
    }
  }

  async __onClose(event: CloseEvent): Promise<void> {
    if (this.shouldReconnect) {
      await this.internalConnect()
    }
  }

  isNotClosed() {
    if (this.socket) {
      return this.socket.readyState !== WebSocket.CLOSED
    }
    return false
  }
}

export { Socket }