import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPublishedSession, getHubSessions } from "../../api";
import { RootState } from "../../app/store";


export const fetchPublishedSession = createAsyncThunk(
    'publishedSession/get',
    async ({ username, sessionName }: { username?: string, sessionName?: string }) => {
        const publishedSessionId = getPublishedSessionId(username, sessionName);
        const response = await getPublishedSession(publishedSessionId);
        return { session: response.data, username, sessionName};
    }
);

export const fetchHubSessions = createAsyncThunk(
    'publishedSession/getHubSessions',
    async () => {
        const response = await getHubSessions();
        return response.data;
    }
);

export const getPublishedSessionId = (username?: string, name?: string) => {
    return `${username}/${name}`;
}

export const splitPublishedSessionId = (publishedSessionId: string) => {
    const [username, name] = publishedSessionId.split('/');
    return { username, name };
}

const getPublishedSessionLoadingState = (state: PublishedSessionState, username?: string, name?: string) => {
    const publishedSessionId = getPublishedSessionId(username, name);
    return state.states[publishedSessionId] || 'idle';
}

type RequestStatus = 'idle' | 'loading' | 'failed' | 'succeeded';

export interface PublishedSessionState {
    hubSessionIds: string[]
    getHubSessionsState: RequestStatus
    states: Record<string, RequestStatus>
    error: string
}

const initialState: PublishedSessionState = {
    hubSessionIds: [],
    getHubSessionsState: 'idle',
    states: {},
    error: ''
}

const publishedSessionsSlice = createSlice({
    name: 'publishedSessions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHubSessions.fulfilled,
                (state, action) => {
                    return { ...state, hubSessionIds: action.payload.map(session => session.id), sessionsInfoState: 'succeeded' }
                })
            .addCase(fetchHubSessions.pending,
                (state, action) => {
                    return { ...state, sessionsInfoState: 'loading' }
                })
            .addCase(fetchHubSessions.rejected,
                (state, action) => {
                    return { ...state, sessionsInfoState: 'failed' }
                })
            .addCase(fetchPublishedSession.fulfilled,
                (state, action) => {
                    const sessionId = getPublishedSessionId(action.payload.username, action.payload.sessionName);
                    return { ...state, states: { ...state.states, [sessionId]: 'succeeded' } }
                })
            .addCase(fetchPublishedSession.rejected,
                (state, action) => {
                    const sessionId = getPublishedSessionId(action.meta.arg.username, action.meta.arg.sessionName);
                    return { ...state, states: { ...state.states, [sessionId]: 'failed' } }
                })
            .addCase(fetchPublishedSession.pending,
                (state, action) => {
                    const sessionId = getPublishedSessionId(action.meta.arg.username, action.meta.arg.sessionName);
                    return { ...state, states: { ...state.states, [sessionId]: 'loading' } }
                })
    }
});

export default publishedSessionsSlice.reducer;
export const selectPublishedSessionsInfoStates = (state: RootState) => state.publishedSessions.states;
export const selectHubSessionIds = (state: RootState) => state.publishedSessions.hubSessionIds;
export const selectGetHubSessionsState = (state: RootState) => state.publishedSessions.getHubSessionsState;
export const selectPublishedSessionState = (username?: string, name?: string) => (state: RootState) => getPublishedSessionLoadingState(state.publishedSessions, username, name);