import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { createComment, deleteComment, editComment, getCommentsForInteraction } from "../../api";
import { addInteractionEvent } from "../session/sessionSlice";
import { InteractionEvent, Comment } from "../../types";


export interface CommentsState {
    comments: Comment[],
}

const initialState: CommentsState = {
    comments: [],
}

export const fetchCommentsForInteraction = createAsyncThunk(
    'comments/getForInteraction',
    async ({ sessionId, interactionId }: { sessionId: string, interactionId: string }) => {
        const response = await getCommentsForInteraction(sessionId, interactionId);
        return response.data;
    }
);

export const fetchCreateInteractionEventComment = createAsyncThunk(
    'comments/createForInteractionEvent',
    async ({ sessionId, interactionId, interactionEventId, content }: { sessionId: string, interactionId: string, interactionEventId: string, content: string }) => {
        await createComment(content, sessionId, interactionId, interactionEventId);
    }
);

export const fetchEditComment = createAsyncThunk(
    'comments/editComment',
    async ({ commentId, content }: { commentId: string, content: string }) => {
        await editComment(content, commentId);
    }
);

export const fetchDeleteComment = createAsyncThunk(
    'comments/deleteComment',
    async ({ commentId }: { commentId: string }) => {
        await deleteComment(commentId);
    }
);

const handleCreateCommentEvent = (current_comments: Comment[], comment: Comment) => {
    return [...current_comments.filter(c => c.id !== comment.id), comment];
}

const handleDeleteCommentEvent = (current_comments: Comment[], comment_id: string) => {
    return [...current_comments.filter(c => c.id !== comment_id)];
}

const handleEditCommentEvent = (current_comments: Comment[], comment: Comment) => {
    return current_comments.map(c => c.id === comment.id ? comment : c);
}

const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(addInteractionEvent,
                (state, action: PayloadAction<{ sessionId: string, interactionId: string, event: InteractionEvent }>) => {
                    const { event } = action.payload
                    if (event.event_type === "CreateCommentEvent" && event.comment) {
                        return { comments: handleCreateCommentEvent(state.comments, event.comment) };
                    }
                    else if (event.event_type === "DeleteCommentEvent" && event.comment_id) {
                        return { comments: handleDeleteCommentEvent(state.comments, event.comment_id) };
                    }
                    else if (event.event_type === "EditCommentEvent" && event.comment) {
                        return { comments: handleEditCommentEvent(state.comments, event.comment) };
                    }
                })
            .addCase(fetchCommentsForInteraction.fulfilled, (state, action) => {
                return { comments: action.payload };
            })
    }
});
export default commentsSlice.reducer;
export const selectCommentsForInteraction = (sessionId: string, interactionId: string) => (state: RootState) => state.comments.comments.filter(comment => comment.session_id === sessionId && comment.interaction_id === interactionId);
export const selectCommentsForInteractionEvent = (sessionId: string, interactionId: string, interactionEventId: string) => (state: RootState) => state.comments.comments.filter(comment => comment.session_id === sessionId && comment.interaction_id === interactionId && comment.interaction_event_id === interactionEventId);
export const selectCommentById = (commentId?: string) => (state: RootState) => state.comments.comments.find(comment => comment.id === commentId);