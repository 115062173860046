import { Avatar, Button, ButtonProps, CardHeader } from "@mui/material";
import { useAuth } from "../auth";
import React from "react";
import { ProfileActionsPopover } from "./popover/profileActionsPopover";
import { usePopover } from "../hooks/usePopover";
import { getMemberInfoFromUser } from "../members";

const InnerAvatar = ({ disableName = false, ...props }: ButtonProps & { disableName?: boolean }) => {

    const { user } = useAuth();

    const memberInfo = user ? getMemberInfoFromUser(user) : undefined;

    const image = user?.image
    const avatar = <Avatar src={image} sx={{width: '40px', height: '40px'}}></Avatar>

    const title = disableName ? undefined : memberInfo?.displayName

    if (disableName) {
        return <Button {...props}  sx={{padding: 0, minWidth: 0}} fullWidth >
            {avatar}
        </Button>

    }
    return <Button {...props} fullWidth >
        <CardHeader sx={{ padding: 0 }} title={title} avatar={avatar} />
    </Button>
}
export const CurrentUserAvatar = ({ disableName = false }: { disableName?: boolean }) => {

    const { anchorEl, handleClick, handleClose, open } = usePopover();

    return <>
        <ProfileActionsPopover open={open} anchorEl={anchorEl} onClose={handleClose} />
        <InnerAvatar onClick={handleClick} disableName={disableName} />
    </>

}