import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Modal, ModalActions, ModalButton, ModalContents, ModalFormField, ModalHeader } from "./modal";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect } from "react";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { userFromAmplifyUser } from "../../types";
import { getMemberInfoAvatar, getMemberInfoFromUser } from "../../members";
import { Auth } from "aws-amplify";
import { enqueueSnackbar } from "../../features/notifications/notificationsSlice";
import { useAppDispatch } from "../../app/hooks";
import type { AmplifyUser } from '@aws-amplify/ui';
import { manageSubscriptionUrl } from "../../App";


const schema = yup.object({
    firstName: yup.string().max(64).required(),
    lastName: yup.string().max(64).required(),
}).required();

export const ProfileModal = ({ open, onClose, amplifyUser }: { open: boolean, onClose: () => void; onAccept: (name: string) => void, url?: string, amplifyUser: AmplifyUser }) => {

    const dispatch = useAppDispatch();
    const user = userFromAmplifyUser(amplifyUser);
    const memberInfo = getMemberInfoFromUser(user);
    const avatar = getMemberInfoAvatar(memberInfo, { sx: { width: '81px', height: '81px' } });

    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });

    useEffect(() => {
        setValue("firstName", user.first_name || "");
        setValue("lastName", user.last_name || "");
    }, [open, setValue, user.first_name, user.last_name]);

    const onSubmit = (data: yup.InferType<typeof schema>) => {
        Auth.updateUserAttributes(amplifyUser, { given_name: data.firstName, family_name: data.lastName }).then((x) => {
            dispatch(
                enqueueSnackbar({
                    message: "Profile updated",
                    options: {
                        variant: "success",
                    },
                })
            );
        }
        )
    }

    const doOnClose = () => {
        reset();
        onClose();
    }

    const subscriptionStatus = "Free"

    const onManageSubscription = () => {
        window.open(manageSubscriptionUrl, '_blank')?.focus();
    }

    return <Modal open={open} onClose={onClose} >
        <ModalContents>
            <ModalHeader>
                Profile
            </ModalHeader>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                {avatar}
            </Box>
            <ModalFormField title="Subscription">
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center"}}>
                    <Typography variant="h3">{subscriptionStatus}</Typography>
                    <Button variant="outlined" color="primary" onClick={onManageSubscription} >Manage</Button>
                </Box>
            </ModalFormField>
            <ModalFormField title="Username">
                <TextField fullWidth value={user.username} disabled
                    InputProps={{
                        endAdornment: (
                            <Box color="text.secondary" sx={{ display: 'flex', alignContent: 'center' }}>
                                <LockOutlinedIcon fontSize="small" color="inherit" />
                            </Box>
                        ),
                    }}
                />
            </ModalFormField>
            <ModalFormField title="E-mail">
                <TextField fullWidth value={user.email} disabled
                    InputProps={{
                        endAdornment: (
                            <Box color="text.secondary" sx={{ display: 'flex', alignContent: 'center' }}>
                                <LockOutlinedIcon fontSize="small" color="inherit" />
                            </Box>
                        ),
                    }}
                />
            </ModalFormField>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form" id="upload-link-modal-form">
                <ModalFormField title="First Name">
                    <TextField error={!!errors.firstName} helperText={errors.firstName?.message || ""} {...register("firstName")} fullWidth />
                </ModalFormField>
                <ModalFormField title="Last Name">
                    <TextField error={!!errors.lastName} helperText={errors.lastName?.message || ""} {...register("lastName")} fullWidth />
                </ModalFormField>
            </form>
        </ModalContents>
        <Divider />
        <ModalActions>
            <ModalButton onClick={doOnClose} variant="text" color="primary" size="large">Close</ModalButton>
            <ModalButton form="upload-link-modal-form" type="submit" variant="contained" color="contrast" size="large">Save</ModalButton>
        </ModalActions>
    </Modal>;
};
