const forceDateTimeStringToUTC = (datetime: string) => {
    return datetime.endsWith("Z") ? datetime : datetime + "Z";
}

export const datetimeStringToDate = (datetime: string) => {
    const utcDateTime = forceDateTimeStringToUTC(datetime);
    return new Date(utcDateTime)
}

export const dateToHourReadable = (date: Date, locale: string = "en-US") => {
    return date.toLocaleTimeString(locale, { hour: 'numeric', minute: 'numeric', hour12: true })
}

export const datetimeStringToHourReadable = (datetime: string, locale: string = "en-US") => {
    const date = datetimeStringToDate(datetime)
    return dateToHourReadable(date, locale)
}

export const datetimeStringToDatetimeReadable = (datetime: string, locale: string = "en-US") => {
    const date = datetimeStringToDate(datetime)
    return date.toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
}

export const dateToDatetimeReadableNoWeekday = (date: Date, locale: string = "en-US") => {
    return date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })
}

export const datetimeStringToDatetimeReadableNoWeekday = (datetime: string, locale: string = "en-US") => {
    const date = datetimeStringToDate(datetime)
    return dateToDatetimeReadableNoWeekday(date, locale)
}

export const dateTimeStringToReadableTimeSince = (datetime: string, locale: string = "en-US") => {
    const utcDateTime = forceDateTimeStringToUTC(datetime);
    const date = new Date(utcDateTime).getTime()
    const currentUTC = new Date().getTime();

    // Calculate the difference in milliseconds
    const timeDifferenceInMilliseconds = currentUTC - date;

    // Convert milliseconds to seconds
    const timeDifferenceInSeconds = Math.floor(timeDifferenceInMilliseconds / 1000);
    if (timeDifferenceInSeconds < 60) {
        return "< 1m"
    } else if (timeDifferenceInSeconds < 3600) {
        return `${Math.floor(timeDifferenceInSeconds / 60)}m`
    } else if (timeDifferenceInSeconds < 86400) {
        return `${Math.floor(timeDifferenceInSeconds / 3600)}h`
    } else if (timeDifferenceInSeconds < 604800) {
        return `${Math.floor(timeDifferenceInSeconds / 86400)}d`
    } else if (timeDifferenceInSeconds < 2628000) {
        return `${Math.floor(timeDifferenceInSeconds / 604800)}w`
    } else {
        return `${Math.floor(timeDifferenceInSeconds / 2628000)}mo`
    }
}