import { Box, Button, Modal as MuiModal, ModalProps, Typography, TypographyProps, styled, Paper, SxProps } from "@mui/material";

export const ModalButton = styled(Button)(({ theme }) => ({
    fontWeight: 600,
}));

export const ModalHeader = (props: TypographyProps) => {
    return <Typography variant="h2" {...props} />
}

export const ModalFormFieldLabel = (props: TypographyProps) => {
    return <Typography variant="body1" color="text.secondary" fontWeight={600} {...props} />
}

export const ModalFormField = ({ title, children, sx}: { title?: string, children: React.ReactNode, sx?: SxProps}) => {
    return <Box className="modal-form-field" sx={sx}>
        {title && <ModalFormFieldLabel>{title}</ModalFormFieldLabel>}
        {children}
    </Box>
}

export const ModalActions = ({ children }: { children: React.ReactNode }) => {
    return <Box className="modal-actions">
        {children}
    </Box>
}

export const ModalContents = ({ children }: { children: React.ReactNode }) => {
    return <Box className="modal-content">
        {children}
    </Box>
}

interface _ModalProps extends Omit<ModalProps, 'children'> {
    children: React.ReactNode;
    small?: boolean;
}

const ModalPaper = styled(Paper)(({ theme }) => ({
    background: theme.palette.background.default,
}));


export const Modal = (props: _ModalProps) => {
    const { children, sx, small, ...passedProps } = { ...props };
    const passedSx = { ...sx, display: 'flex' };

    // const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <MuiModal {...passedProps} sx={passedSx}>
            <ModalPaper variant="outlined" className={`modal ${small ? 'modal-small' : ''}`}
            >
                {/* {
                    matches &&
                    <Box className="modal-mobile-header">
                        <IconButton onClick={() => props.onClose?.({}, 'escapeKeyDown')}><CloseIcon /></IconButton>
                    </Box>
                } */}
                {children}
            </ModalPaper>
        </MuiModal>
    )
}