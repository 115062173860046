import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { Auth } from "aws-amplify";
import { useAuth } from "../auth";
import AuthPage from "./auth";
import VerificationInput from "react-verification-input";

export const VerifyPage = () => {
    const [verificationCode, setVerificationCode] = useState<string>("");
    const [error, setIsError] = useState<boolean>(false);
    const { isVerified, amplifyUser, loading } = useAuth(true)


    const verificationCodeValid = verificationCode.length === 6;

    if (loading) {
        return <></>
    }

    if (isVerified) {
        return <Navigate to="/" replace />;
    }

    const verifyUser = async () => {
        // @ts-ignore
        if (amplifyUser && amplifyUser.username && verificationCodeValid) {
            try {
                setIsError(false);
                await Auth.verifyUserAttributeSubmit(amplifyUser, "email", verificationCode);
                await Auth.currentAuthenticatedUser({ bypassCache: true });
                window.location.href = "/";
            } catch (e) {
                setIsError(true);
            }
        }
    }

    const sendVerificationCode = async () => {
        if (amplifyUser) {
            await Auth.verifyUserAttribute(amplifyUser, "email");
        }
    }

    const logOut = () => {
        Auth.signOut().then(() => {
            window.location.href = "/";
        })
    }

    const verifyForm = <Box className="verify-form">
        <Typography variant="h1">Verify your email</Typography>
        <Typography variant="body1" color="text.secondary">Enter the code we sent to your email</Typography>
        {error && <Typography variant="body1" color="error">Incorrect verification code</Typography>}
        <VerificationInput
            validChars="0-9"
            onChange={setVerificationCode}
            classNames={{
                container: "verification-input-container",
                character: "verification-input-character",
                characterInactive: "character--inactive",
                characterSelected: "verification-input-character-selected",
                characterFilled: "character--filled",
            }} />
        <Button variant="contained" fullWidth disabled={!verificationCodeValid} onClick={verifyUser}>
            Verify
        </Button>
        <Button variant="text" fullWidth onClick={sendVerificationCode}>
            <Typography variant="body1" color="text.secondary">
                Didn't recieve a code? <u>Send again</u>
            </Typography>
        </Button>
        <Button variant="text" fullWidth onClick={logOut}>
            <Typography variant="body1" color="text.secondary">
                Log out
            </Typography>
        </Button>
    </Box>

    return <AuthPage>
        <Box className="auth-action-panel-content">
            {
                amplifyUser ? verifyForm : <CircularProgress />
            }
        </Box>
    </AuthPage>
}

