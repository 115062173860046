import Fuse, { FuseResult} from "fuse.js";
import { Session } from "../types";
import React, { useMemo } from "react";

const memberWeight = 2
const fieldWeight = 1
const nameWeight = 10
const levelWeightDecay = 0.7

const sessionFuse = (sessions: Session[]) => {
    return new Fuse(sessions, {
        includeScore: true,
        includeMatches: true,
        ignoreLocation: true,
        minMatchCharLength: 2,
        threshold: 0.3,
        keys: [
            { name: 'name', weight: 10 },
            { name: 'published_session.name', weight: nameWeight * levelWeightDecay },
            { name: 'published_session.description', weight: 1 },
            { name: 'members.user.email', weight: memberWeight },
            { name: 'members.user.username', weight: memberWeight },
            { name: 'members.user.first_name', weight: memberWeight },
            { name: 'members.user.last_name', weight: memberWeight },
            { name: 'contents.name', weight: nameWeight * levelWeightDecay },
            { name: 'contents.tags.title', weight: nameWeight * levelWeightDecay },
            { name: 'contents.source', weight: nameWeight * levelWeightDecay },
            { name: 'interactions.name', weight: nameWeight * levelWeightDecay },
            { name: 'interactions.events.response', weight: fieldWeight * levelWeightDecay },
            { name: 'interactions.events.query', weight: fieldWeight * levelWeightDecay },
        ]
    })
}

const interactionFuse = (sessions: Session[]) => {
    const interactions = sessions.flatMap(session => session.interactions.map(interaction => ({ ...interaction, session })))

    return new Fuse(interactions, {
        includeScore: true,
        includeMatches: true,
        ignoreLocation: true,
        minMatchCharLength: 2,
        threshold: 0.3,
        keys: [
            { name: 'name', weight: nameWeight * levelWeightDecay },
            { name: 'events.response', weight: fieldWeight * levelWeightDecay },
            { name: 'events.query', weight: fieldWeight * levelWeightDecay },
        ]
    })
}

interface SearchEverythingResults {
    sessions: FuseResult<Session>[],
    interactions: FuseResult<any>[]
}

export interface UseSearchEverythingResult {
    query: string,
    setQuery: (query: string) => void,
    searchResults: SearchEverythingResults
}

export const useSearchEverything = (sessions: Session[]): UseSearchEverythingResult => {
    const [query, setQuery] = React.useState('')
    const sessionsFuse = useMemo(() => sessionFuse(sessions), [sessions])
    const interactionsFuse = useMemo(() => interactionFuse(sessions), [sessions])

    const sessionsResults = useMemo(() => {
        if (!query) {
            return []
        }
        return sessionsFuse.search(query)
    }, [query, sessionsFuse]);

    const interactionsResults = useMemo(() => {
        if (!query) {
            return []
        }
        return interactionsFuse.search(query)
    }, [query, interactionsFuse]);

    return { query, setQuery, searchResults: {sessions: sessionsResults, interactions: interactionsResults} }
}