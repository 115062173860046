import React, { useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../../auth';
import * as Sentry from "@sentry/react";
import { feedback } from '../../App';

export function RequireGuest({ children }: { children: JSX.Element }): JSX.Element {
    const location = useLocation();

    const { loading, isAuthenticated, user, isGuest, isVerified } = useAuth();

    useEffect(() => {
        if (user) {
            Sentry.setUser({
                id: user.id,
            })
        }
    }, [user]);

    const isAllowed = (isAuthenticated && isVerified) || isGuest;

    useEffect(() => {
        if (isAuthenticated) {
            const widget = feedback.getWidget()
            if (!widget) {
                feedback.createWidget();
            }
        }
    }, [isAuthenticated]);

    if (loading) {
        return <></>
    }

    if (!isAllowed) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}