import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';
import { connectToSocket } from '../middleware/socket';


export const SocketConnector = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {

        const disconnect = () => {
            dispatch(connectToSocket());
        }

        dispatch(connectToSocket());

        return () => {
            disconnect();
        }
    }, [dispatch]);

    return <></>

}