import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchPublishedSession, selectPublishedSessionState } from "../features/session/publishedSessionSlice";
import { useEffect } from "react";

export const usePublishedSession = ({ username, sessionName }: { username?: string, sessionName?: string }) => {
    const dispatch = useAppDispatch();

    const publishedSessionState = useAppSelector(selectPublishedSessionState(username, sessionName));

    useEffect(() => {
        if (username && sessionName && (publishedSessionState === 'idle')) {
            dispatch(fetchPublishedSession({ username: username, sessionName: sessionName }))
        }
    }, [dispatch, sessionName, username, publishedSessionState]);

    return { publishedSessionState };

}