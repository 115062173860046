import React from 'react';
import { useAppDispatch } from '../../app/hooks';
import { fetchQuery } from '../../features/session/sessionSlice';
import { Box, TextField } from '@mui/material';
import { Session, Interaction } from '../../types';


export const InteractionComponentInput = ({ interaction, session }: { interaction: Interaction; session: Session; }) => {
    const dispatch = useAppDispatch();
    const [query, setQuery] = React.useState("");
    const queryInvalid = query === "";

    const onSubmit = (query: string) => {
        if (interaction)
            dispatch(fetchQuery({ sessionId: session.id, interactionId: interaction.id, query: query }));
    };

    const doSubmit = () => {
        if (queryInvalid) {
            return;
        }
        onSubmit(query);
        setQuery("");
    };

    const onEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            doSubmit();
        }
    };

    return <Box className="internal-page-footer">
        <TextField
            label=""
            placeholder="Ask anything..."
            variant="outlined"
            onChange={e => setQuery(e.target.value)} onKeyDown={onEnterKeyDown} value={query} fullWidth />
    </Box>;
};
