import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { fetchInteraction } from "./sessionSlice";
import { Session } from "../../types";

export const InteractionLoaderComponent = ({ session, interactionId }: { session: Session, interactionId: string }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (session.interactions.find(i => i.id === interactionId) === undefined) {
            dispatch(fetchInteraction({ sessionId: session.id, interactionId }));
        }
    }, [dispatch, session.id, interactionId, session.interactions]);

    return null;
}