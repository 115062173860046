import { Box, Typography } from "@mui/material";
import MuiLink from '@mui/material/Link';
import { InternalPage } from "./internalPage";
import { Link } from "react-router-dom";


const HelpPageBody = () => {
    return <Box className="internal-page-center">
        <Box className="internal-page-content-body-header">
            <Typography variant="h1">Help</Typography>
        </Box>
        <Box className="internal-page-content-body">
            <Typography variant="body1">For help contact support@brua.io</Typography>
            <Box className="spacing-medium"/>
            <MuiLink component={Link} to="/privacyPolicy">Privacy Policy</MuiLink>
            <Box className="spacing-medium"/>
            <MuiLink component={Link} to="/termsOfService">Terms of Service</MuiLink>
        </Box>

    </Box>
}

export const HelpPage = () => {
    return <InternalPage>
        <HelpPageBody />
    </InternalPage>
};