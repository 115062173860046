import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addSessionMember, createSession, createInteraction, deleteSession, deleteInteraction, renameInteraction, getContent, getSessionMember, getPublishedSession, getSession, getSessions, linkSession, message, publishSession, query, removeContent, uploadFile, uploadUrl, queryWithoutInteraction, addInteractionMember, getContentChunk, updateSession, addInteractionEventFeedback, favoriteSession, unfavoriteSession, setInteractionSessionPublic, updateContent, unlinkSession, getInteraction } from '../../api';

import { MemberRole, PublishedSessionSettings, Session, SessionAction, Interaction, InteractionEvent, TextChunk } from '../../types';
import { RootState } from '../../app/store';
import { fetchHubSessions, fetchPublishedSession, getPublishedSessionId } from './publishedSessionSlice';

type RequestStatus = 'idle' | 'loading' | 'failed' | 'succeeded';

export interface ContentReference {
    contentId: string
    chunkId?: string
}

interface SessionsState {
    sessions: Session[]
    sessionsLoadedState: RequestStatus,
    lastCreatedSessionId?: string
    lastCreatedInteractionId?: string,
    currentSessionId?: string
    currentInteractionId?: string

    contentRequests: { [key: string]: { status: RequestStatus } }

    urlUploadState: RequestStatus
    urlUploadError: string
    fileUploadState: RequestStatus
    fileUploadError: string

    fetchQueryNewInteractionState: RequestStatus

    addMemberState: RequestStatus
    addMemberError: string

    updateSessionState: RequestStatus

    currentContentReferences: ContentReference[]
    contentChunks: {
        [key: string]: { chunk?: TextChunk, status: RequestStatus }
    }
}

const initialState: SessionsState = {
    sessions: [],
    sessionsLoadedState: 'idle',
    lastCreatedSessionId: undefined,
    lastCreatedInteractionId: undefined,
    currentSessionId: undefined,
    currentInteractionId: undefined,
    contentRequests: {},
    urlUploadState: 'idle',
    urlUploadError: '',
    fileUploadState: 'idle',
    fileUploadError: '',
    addMemberState: 'idle',
    addMemberError: '',
    updateSessionState: 'idle',
    fetchQueryNewInteractionState: 'idle',
    currentContentReferences: [],
    contentChunks: {}
};

export const fetchSessions = createAsyncThunk(
    'session/fetchSessions',
    async () => {
        const response = await getSessions();
        return response.data;
    }
)

export const fetchCreateSession = createAsyncThunk(
    'session/createSession',
    async ({ name, shortDescription }: { name: string, shortDescription?: string }) => {
        const response = await createSession(name, shortDescription);
        return response.data;
    }
)

export const fetchUpdateSession = createAsyncThunk(
    'session/updateSession',
    async ({ sessionId, name, description, isLinkPublic: is_link_public, basePrompt: base_prompt, shortDescription: short_description, theme }:
        { sessionId: string, name?: string, description?: string, isLinkPublic?: boolean, basePrompt?: string, shortDescription?: string, theme?: string }) => {
        const response = await updateSession(sessionId, { name, description, is_link_public, base_prompt, short_description, theme });
        return { session: response.data };
    }
)

export const fetchDeleteSession = createAsyncThunk(
    'session/deleteSession',
    async (sessionId: string) => {
        const response = await deleteSession(sessionId);
        return response.data;
    }
)

export const fetchUploadUrl = createAsyncThunk(
    'session/uploadUrl',
    async ({ sessionId, url }: { sessionId: string, url: string }) => {
        await uploadUrl(sessionId, url);
        return { sessionId: sessionId };
    }
)

export const fetchUploadFile = createAsyncThunk(
    'session/uploadFile',
    async ({ sessionId, file }: { sessionId: string, file: File }) => {
        await uploadFile(sessionId, file);
        return { sessionId: sessionId };
    }

)

export const fetchQuery = createAsyncThunk(
    'session/query',
    async (payload: { sessionId: string, interactionId: string, query: string }, { rejectWithValue }) => {
        try {
            await query(payload.sessionId, payload.interactionId, payload.query);
            return { sessionId: payload.sessionId, interactionId: payload.interactionId };
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    }
)

export const fetchMessage = createAsyncThunk(
    'session/message',
    async (payload: { sessionId: string, message: string }) => {
        await message(payload.sessionId, payload.message);
        return { sessionId: payload.sessionId };
    }
)

export const fetchSession = createAsyncThunk(
    'session/getSession',
    async (sessionId: string) => {
        const response = await getSession(sessionId);
        return { session: response.data };
    }
)

export const fetchAddMember = createAsyncThunk(
    'session/addMember',
    async ({ sessionId, userEmail, role }: { sessionId: string, userEmail: string, role: MemberRole }) => {
        const response = await addSessionMember(sessionId, userEmail, role);
        return { sessionId: sessionId, member: response.data };
    }
)

export const fetchAddInteractionMember = createAsyncThunk(
    'session/addInteractionMember',
    async ({ sessionId, interactionId, userId }: { sessionId: string, interactionId: string, userId: string }) => {
        await addInteractionMember(sessionId, interactionId, userId);
        return { sessionId: sessionId, interactionId: interactionId, userId: userId };
    }
)

export const fetchCreateInteraction = createAsyncThunk(
    'session/createInteraction',
    async ({ sessionId, name }: { sessionId: string, name: string }) => {
        const response = await createInteraction(sessionId, name);
        const updatedSession = await getSession(sessionId);
        return { session: updatedSession.data, interactionId: response.data };
    }
)

export const fetchAndAddContent = createAsyncThunk(
    'session/fetchAndAddContent',
    async ({ sessionId, contentId }: { sessionId: string, contentId: string }) => {
        const response = await getContent(sessionId, contentId);
        return { sessionId: sessionId, content: response.data };
    },
    {
        condition: ({ sessionId, contentId }: { sessionId: string, contentId: string }, { getState }) => {
            const contentRequests = (getState() as RootState).session.contentRequests;
            const status = contentRequests[contentId]?.status || 'idle';
            const shouldFetch = status !== 'loading';
            return shouldFetch;
        }, dispatchConditionRejection: false
    }
)

export const fetchAndAddMember = createAsyncThunk(
    'session/fetchAndAddMember',
    async ({ sessionId, memberId }: { sessionId: string, memberId: string }) => {
        const response = await getSessionMember(sessionId, memberId);
        return { sessionId: sessionId, member: response.data };
    }
)

export const fetchRemoveContent = createAsyncThunk(
    'session/removeContent',
    async ({ sessionId, contentId }: { sessionId: string, contentId: string }) => {
        await removeContent(sessionId, contentId);
        return { sessionId: sessionId, contentId: contentId };
    }
)
export const fetchUpdateContent = createAsyncThunk(
    'session/updateContent',
    async ({ sessionId, contentId, isPrimary }: { sessionId: string, contentId: string, isPrimary: boolean }) => {
        await updateContent(sessionId, contentId, isPrimary);
        return { sessionId: sessionId, contentId: contentId, isPrimary: isPrimary };
    }
)


export const fetchPublishSession = createAsyncThunk(
    'session/publishSession',
    async ({ sessionId, name, description, settings }: { sessionId: string, name: string, description: string, settings: PublishedSessionSettings }) => {
        const response = await publishSession(sessionId, name, description, settings);
        const updatedSession = await getSession(sessionId);
        return { sessionId: sessionId, name: name, publishedId: response.data, updatedSession: updatedSession.data };
    }
)


export const fetchQueryNewInteraction = createAsyncThunk(
    'session/fetchQueryNewInteraction',
    async (payload: { sessionId: string, query: string, onNewInteractionCreated: (interactionId: string) => void }, { rejectWithValue }) => {
        try {
            const response = await queryWithoutInteraction(payload.sessionId, payload.query);
            const updatedSession = await getSession(payload.sessionId);
            payload.onNewInteractionCreated(response.data.id);
            return { session: updatedSession.data, interactionId: response.data.id };
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
    }
)

export const fetchLinkSession = createAsyncThunk(
    'session/linkSession',
    async ({ sessionId, linkedSessionId }: { sessionId: string, linkedSessionId: string }, { rejectWithValue }) => {
        try {
            await linkSession(sessionId, linkedSessionId);
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
        return { sessionId: sessionId, linkedSessionId: linkedSessionId };
    }
)

export const fetchUnlinkSession = createAsyncThunk(
    'session/unlinkSession',
    async ({ sessionId, linkedSessionId }: { sessionId: string, linkedSessionId: string }, { rejectWithValue }) => {
        try {
            await unlinkSession(sessionId, linkedSessionId);
        } catch (e: any) {
            return rejectWithValue(e.response.data);
        }
        return { sessionId: sessionId, linkedSessionId: linkedSessionId };
    }
)

export const fetchLinkPublishedSession = createAsyncThunk(
    'session/linkPublishedSession',
    async ({ sessionId, username, sessionName }: { sessionId: string, username: string, sessionName: string }) => {
        const publishedSessionId = getPublishedSessionId(username, sessionName);
        const response = await getPublishedSession(publishedSessionId);
        const linkedSessionId = response.data.id;
        await linkSession(sessionId, linkedSessionId);
        return { sessionId: sessionId, linkedSessionId: linkedSessionId };
    }
)

export const fetchDeleteInteraction = createAsyncThunk(
    'session/deleteInteraction',
    async ({ sessionId, interactionId }: { sessionId: string, interactionId: string }) => {
        await deleteInteraction(sessionId, interactionId);
        return { sessionId: sessionId, interactionId: interactionId };
    }
)

export const fetchInteraction = createAsyncThunk(
    'session/getInteraction',
    async ({ sessionId, interactionId }: { sessionId: string, interactionId: string }) => {
        const response = await getInteraction(sessionId, interactionId);
        return { sessionId: sessionId, interaction: response.data };
    }
)

export const fetchRenameInteraction = createAsyncThunk(
    'session/renameInteraction',
    async ({ sessionId, interactionId, name }: { sessionId: string, interactionId: string, name: string }) => {
        await renameInteraction(sessionId, interactionId, name);
        return { sessionId: sessionId, interactionId: interactionId, name: name };
    }
)

export const fetchContentChunk = createAsyncThunk(
    'session/fetchContentChunk',
    async ({ sessionId, contentId, chunkId }: { sessionId: string, contentId: string, chunkId: string }) => {
        const response = await getContentChunk(sessionId, contentId, chunkId);
        return { sessionId: sessionId, contentId: contentId, chunk: response.data };
    }

)
export const fetchAddInteractionEventFeedback = createAsyncThunk(
    'session/addInteractionEventFeedback',
    async ({ sessionId, interactionId, eventId, feedback }: { sessionId: string, interactionId: string, eventId: string, feedback: string[] }) => {
        await addInteractionEventFeedback(sessionId, interactionId, eventId, feedback);
    }
)

export const fetchFavoriteSession = createAsyncThunk(
    'session/favoriteSession',
    async ({ sessionId, shouldFavorite }: { sessionId: string, shouldFavorite: boolean }) => {
        if (shouldFavorite) {
            await favoriteSession(sessionId);
        } else {
            await unfavoriteSession(sessionId);
        }

        return { sessionId: sessionId, isFavorite: shouldFavorite };
    }

)
export const fetchSetInteractionSessionPublic = createAsyncThunk(
    'session/setInteractionSessionPublic',
    async ({ sessionId, interactionId, shouldBePublic }: { sessionId: string, interactionId: string, shouldBePublic: boolean }) => {
        await setInteractionSessionPublic(sessionId, interactionId, shouldBePublic);
        return { sessionId: sessionId, interactionId: interactionId, shouldBePublic: shouldBePublic };
    }
)

const handleTextResponseEventChunk = (interaction: Interaction, chunkEvent: InteractionEvent): Interaction => {
    let exists = false;
    const chunk = chunkEvent.chunk ? chunkEvent.chunk : '';
    const interactionEvents: InteractionEvent[] = [];
    interaction.events.forEach(event => {
        if (event.id === chunkEvent.parent_id) {
            exists = true;
            const response = event.response ? event.response : '';
            interactionEvents.push({ ...event, response: response + chunk });
        } else {
            interactionEvents.push(event);
        }
    });

    if (!exists) {
        const parent_id = chunkEvent.parent_id ? chunkEvent.parent_id : '';
        const textResponseEvent = {
            datetime: chunkEvent.datetime, event_type: "TextResponseEvent",
            query_id: chunkEvent.query_id, response: chunk, end_time: undefined, id: parent_id, is_generating: true
        } as InteractionEvent;
        interactionEvents.push(textResponseEvent);
    }
    return { ...interaction, events: interactionEvents };
}

const handleToolResponseEventChunk = (interaction: Interaction, chunkEvent: InteractionEvent): Interaction => {
    let exists = false;
    const chunk = chunkEvent.chunk ? chunkEvent.chunk : '';
    const interactionEvents: InteractionEvent[] = [];
    interaction.events.forEach(event => {
        if (event.id === chunkEvent.parent_id) {
            exists = true;
            const response = event.response ? event.response : '';
            interactionEvents.push({ ...event, response: response + chunk });
        } else {
            interactionEvents.push(event);
        }
    });

    if (!exists) {
        const parent_id = chunkEvent.parent_id ? chunkEvent.parent_id : '';
        const toolResponseEvent = {
            datetime: chunkEvent.datetime, event_type: "ToolResponseEvent",
            query_id: chunkEvent.query_id, response: chunk, end_time: undefined, id: parent_id, is_generating: true,
            tool_id: chunkEvent.tool_id, tool_name: chunkEvent.tool_name, tool_output_type: chunkEvent.tool_output_type
        } as InteractionEvent;
        interactionEvents.push(toolResponseEvent);
    }
    return { ...interaction, events: interactionEvents };
}


function addContentToSession(state: SessionsState, action: any): SessionsState {
    return {
        ...state, sessions: state.sessions.map(session => {
            if (session.id === action.payload.sessionId) {
                const content = action.payload.content;
                const index = session.contents.findIndex(item => item.id === content.id);
                if (index !== -1) {
                    return { ...session, contents: session.contents.map(item => item.id === content.id ? content : item) };
                } else {
                    return { ...session, contents: [...session.contents, action.payload.content] };
                }
            }
            return session;
        })
    };
}

export const addInteractionEventUpdateSession = (session: Session, interactionId: string, event: InteractionEvent) => {
    return {
        ...session, interactions: session.interactions.map(interaction => {
            if (interaction.id === interactionId) {
                if (event.event_type === "TextResponseEventChunk") {
                    return handleTextResponseEventChunk(interaction, event);
                } else if (event.event_type === "TextResponseEvent") {
                    let exists = false;
                    const events = interaction.events.map(interactionEvent => {
                        if (interactionEvent.id === event.id) {
                            exists = true;
                            return { ...interactionEvent, response: event.response, end_time: event.end_time, references: event.references, is_generating: false }
                        }
                        return interactionEvent;
                    })
                    return { ...interaction, events: exists ? events : [...events, event] };
                } else if (event.event_type === "ToolResponseEventChunk") {
                    return handleToolResponseEventChunk(interaction, event);
                } else if (event.event_type === "ToolResponseEvent") {
                    let exists = false;
                    const events = interaction.events.map(interactionEvent => {
                        if (interactionEvent.id === event.id) {
                            exists = true;
                            return { ...interactionEvent, response: event.response, end_time: event.end_time, is_generating: false, references: event.references }
                        }
                        return interactionEvent;
                    })
                    return { ...interaction, events: exists ? events : [...events, event] };
                } else if (event.event_type === "AddMemberEvent" && !!event.added_member_id) {
                    return { ...interaction, members: [...interaction.members, event.added_member_id] };
                }
                else if (event.event_type === "QueryEvent") {
                    return { ...interaction, events: [...interaction.events, event] };
                }
                else {
                    return interaction;
                }
            } else {
                return interaction;
            }

        })
    }

}

export const addSessionActionUpdateSession = (session: Session, action: SessionAction) => {
    let contents = session.contents;
    if (action.action_type === "ProcessContentAction") {
        contents = session.contents.map(content => {
            if (content.id === action.processed_content_id) {
                return { ...content, state: "EMBEDDED" }
            } else {
                return content;
            }
        })
    }
    return { ...session, log: [...session.log, action], contents: contents };
}

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        addInteractionEvent(state, action) {
            const { sessionId, interactionId, event } = action.payload
            return {
                ...state, sessions: state.sessions.map(session => {
                    if (session.id === sessionId) {
                        return addInteractionEventUpdateSession(session, interactionId, event)
                    } else {
                        return session;
                    }
                })
            }
        },
        addSessionAction(state, _action) {
            const { sessionId, action } = _action.payload
            return {
                ...state, sessions: state.sessions.map(session => {
                    if (session.id === sessionId) {
                        return addSessionActionUpdateSession(session, action);
                    } else {
                        return session;
                    }
                })
            }
        },
        setCurrentSessionId(state, action) {
            return { ...state, currentSessionId: action.payload }
        },
        setCurrentInteractionId(state, action) {
            return { ...state, currentInteractionId: action.payload }
        },
        setLastCreatedSessionId(state, action) {
            return { ...state, lastCreatedSessionId: action.payload }
        },
        setLastCreatedInteractionId(state, action) {
            return { ...state, lastCreatedInteractionId: action.payload }
        },
        setCurrentContentReferences(state, action: PayloadAction<ContentReference[]>) {
            return { ...state, currentContentReferences: action.payload }
        },
        resetCurrentContentReferences(state, action) {
            return { ...state, currentContentReferences: [] }
        },
        removeMemberFromSession(state, action: PayloadAction<{ sessionId: string, memberId: string }>) {
            const { sessionId, memberId } = action.payload;
            return {
                ...state, sessions: state.sessions.map(session => {
                    if (session.id === sessionId) {
                        return { ...session, members: session.members.filter(member => member.user.id !== memberId) };
                    } else {
                        return session;
                    }
                })
            }
        },
        removeContentFromSession(state, action: PayloadAction<{ sessionId: string, contentId: string }>) {
            const { sessionId, contentId } = action.payload;
            return {
                ...state, sessions: state.sessions.map(session => {
                    if (session.id === sessionId) {
                        return { ...session, contents: session.contents.filter(content => content.id !== contentId) };
                    } else {
                        return session;
                    }
                })
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSessions.fulfilled, (state, action) => {
                const oldSessions = state.sessions;
                const newSessions = action.payload;
                const mergedSessions = [...newSessions, ...oldSessions.filter(session => !newSessions.find(newSession => newSession.id === session.id))]

                return { ...state, sessions: mergedSessions, sessionsLoadedState: 'succeeded' }
            })
            .addCase(fetchSessions.pending, (state, action) => {
                return { ...state, sessionsLoadedState: 'loading' }
            })
            .addCase(fetchSessions.rejected, (state, action) => {
                return { ...state, sessionsLoadedState: 'failed' }
            })
            .addCase(fetchCreateSession.fulfilled, (state, action) => {
                return { ...state, sessions: [...state.sessions, action.payload], lastCreatedSessionId: action.payload.id }
            })
            .addCase(fetchDeleteSession.fulfilled, (state, action) => {
                return { ...state, sessions: state.sessions.filter(session => session.id !== action.payload.id) }
            })
            .addCase(fetchUploadUrl.fulfilled, (state, action) => {
                return { ...state, urlUploadState: 'idle', urlUploadError: '' }
            })
            .addCase(fetchUploadUrl.pending, (state, action) => {
                return { ...state, urlUploadState: 'loading' }
            })
            .addCase(fetchUploadUrl.rejected, (state, action) => {
                return { ...state, urlUploadState: 'failed' }
            })
            .addCase(fetchUploadFile.fulfilled, (state, action) => {
                return {
                    ...state, fileUploadState: 'idle', fileUploadError: ''
                }
            })
            .addCase(fetchUploadFile.pending, (state, action) => {
                return { ...state, fileUploadState: 'loading' }
            })
            .addCase(fetchUploadFile.rejected, (state, action) => {
                return { ...state, fileUploadState: 'failed' }
            })
            .addCase(fetchInteraction.fulfilled, (state, action:PayloadAction<{sessionId: string, interaction: Interaction}>) => {
                const { sessionId, interaction } = action.payload;
                if (!state.sessions.find(session => session.id === sessionId)?.interactions.find(i => i.id === interaction.id)) {
                    return { ...state, sessions: state.sessions.map(s => { if (s.id === sessionId) { return { ...s, interactions: [...s.interactions, interaction] } } else { return s } }) }
                }
            })
            .addCase(fetchRemoveContent.fulfilled, (state, action) => {
                const { sessionId, contentId } = action.payload;
                return {
                    ...state, sessions: state.sessions.map(session => {
                        if (session.id === sessionId) {
                            return { ...session, contents: session.contents.filter(content => content.id !== contentId) };
                        } else {
                            return session;
                        }
                    })
                }
            })
            .addCase(fetchSession.fulfilled, (state, action) => {
                if (!state.sessions.find(session => session.id === action.payload.session.id)) {
                    return { ...state, sessions: [...state.sessions, action.payload.session] };
                }

                return { ...state, sessions: state.sessions.map(session => { if (session.id === action.payload.session.id) { return action.payload.session } else { return session } }) }
            })
            .addCase(fetchUpdateSession.fulfilled, (state, action) => {
                if (!state.sessions.find(session => session.id === action.payload.session.id)) {
                    return { ...state, sessions: [...state.sessions, action.payload.session], updateSessionState: 'idle' };
                }

                return { ...state, updateSessionState: 'idle', sessions: state.sessions.map(session => { if (session.id === action.payload.session.id) { return action.payload.session } else { return session } }) }
            })
            .addCase(fetchPublishedSession.fulfilled, (state, action) => {
                if (!state.sessions.find(session => session.id === action.payload.session.id)) {
                    return { ...state, sessions: [...state.sessions, action.payload.session] };
                }

                return { ...state, sessions: state.sessions.map(session => { if (session.id === action.payload.session.id) { return action.payload.session } else { return session } }) }
            })
            .addCase(fetchAddMember.fulfilled, (state, action) => {
                return { ...state, addMemberState: 'idle', addMemberError: '' }
            })
            .addCase(fetchAddMember.pending, (state, action) => {
                return { ...state, addMemberState: 'loading', addMemberError: '' }
            })
            .addCase(fetchAddMember.rejected, (state, action) => {
                return { ...state, addMemberState: 'failed', addMemberError: 'Could not add member.' }
            })
            .addCase(fetchUpdateSession.pending, (state, action) => {
                return { ...state, updateSessionState: 'loading' }
            })
            .addCase(fetchUpdateSession.rejected, (state, action) => {
                return { ...state, updateSessionState: 'idle' }
            })
            .addCase(fetchCreateInteraction.fulfilled, (state, action) => {
                const { session, interactionId } = action.payload;
                return { ...state, currentInteractionId: interactionId, sessions: state.sessions.map(s => { if (s.id === session.id) { return session } else { return s } }) }
            })
            .addCase(fetchPublishSession.fulfilled, (state, action) => {
                const { updatedSession } = action.payload;
                return { ...state, sessions: state.sessions.map(s => { if (s.id === updatedSession.id) { return updatedSession } else { return s } }) }
            })
            .addCase(fetchAndAddContent.pending, (state, action) => {
                return {
                    ...state, contentRequests: {
                        ...state.contentRequests, [action.meta.arg.contentId]: {
                            status: 'loading'
                        }
                    }
                }
            })
            .addCase(fetchAndAddContent.rejected, (state, action) => {
                return {
                    ...state, contentRequests: {
                        ...state.contentRequests, [action.meta.arg.contentId]: {
                            status: 'failed'
                        }
                    }
                }
            })
            .addCase(fetchAndAddContent.fulfilled, (state, action) => {
                return {
                    ...addContentToSession(state, action), contentRequests: {
                        ...state.contentRequests, [action.meta.arg.contentId]: {
                            status: 'succeeded'
                        }
                    }
                }
            })
            .addCase(fetchAndAddMember.fulfilled, (state, action) => {
                const { sessionId, member } = action.payload;
                return {
                    ...state, sessions: state.sessions.map(session => {
                        if (session.id === sessionId) {
                            return { ...session, members: [...session.members, member] };
                        } else {
                            return session;
                        }
                    })
                }
            })
            .addCase(fetchQueryNewInteraction.fulfilled, (state, action) => {
                const { session, interactionId } = action.payload;
                return { ...state, currentInteractionId: interactionId, fetchQueryNewInteractionState: 'idle', sessions: state.sessions.map(s => { if (s.id === session.id) { return session } else { return s } }) }
            })
            .addCase(fetchQueryNewInteraction.pending, (state, action) => {
                return { ...state, fetchQueryNewInteractionState: 'loading' }
            })
            .addCase(fetchQueryNewInteraction.rejected, (state, action) => {
                return { ...state, fetchQueryNewInteractionState: 'idle' }
            })
            .addCase(fetchDeleteInteraction.fulfilled, (state, action) => {
                const { sessionId, interactionId } = action.payload;
                return {
                    ...state, sessions: state.sessions.map(session => {
                        if (session.id === sessionId) {
                            return { ...session, interactions: session.interactions.filter(interaction => interaction.id !== interactionId) };
                        } else {
                            return session;
                        }
                    })
                }
            })
            .addCase(fetchRenameInteraction.fulfilled, (state, action) => {
                const { sessionId, interactionId, name } = action.payload;
                return {
                    ...state, sessions: state.sessions.map(session => {
                        if (session.id === sessionId) {
                            return { ...session, interactions: session.interactions.map(interaction => { if (interaction.id === interactionId) { return { ...interaction, name: name } } else { return interaction } }) };
                        } else {
                            return session;
                        }
                    })
                }
            })
            .addCase(fetchContentChunk.pending, (state, action) => {
                return {
                    ...state, contentChunks: {
                        ...state.contentChunks, [action.meta.arg.chunkId]: {
                            status: 'loading'
                        }
                    }
                }
            })
            .addCase(fetchContentChunk.rejected, (state, action) => {
                return {
                    ...state, contentChunks: {
                        ...state.contentChunks, [action.meta.arg.chunkId]: {
                            status: 'failed'
                        }
                    }
                }
            })
            .addCase(fetchContentChunk.fulfilled, (state, action) => {
                return {
                    ...state, contentChunks: {
                        ...state.contentChunks, [action.meta.arg.chunkId]: {
                            status: 'idle', chunk: action.payload.chunk
                        }
                    }
                }
            })
            .addCase(fetchHubSessions.fulfilled, (state, action) => {
                const oldSessions = state.sessions;
                const newSessions = action.payload.map(session => ({ ...session, is_hub_session: true }));
                const mergedSessions = [...newSessions, ...oldSessions.filter(session => !newSessions.find(newSession => newSession.id === session.id))]
                return { ...state, sessions: mergedSessions }
            })
            .addCase(fetchFavoriteSession.fulfilled, (state, action) => {
                const { sessionId, isFavorite } = action.payload;
                const delta = isFavorite ? 1 : -1;
                return {
                    ...state, sessions: state.sessions.map(session => {
                        if (session.id === sessionId) {
                            return { ...session, is_favorite: isFavorite, statistics: { ...session.statistics, n_favorites: session.statistics.n_favorites + delta } }
                        } else {
                            return session;
                        }
                    })
                }
            })
            .addCase(fetchSetInteractionSessionPublic.fulfilled, (state, action) => {
                const { sessionId, interactionId, shouldBePublic } = action.payload;
                return {
                    ...state, sessions: state.sessions.map(session => {
                        if (session.id === sessionId) {
                            return { ...session, interactions: session.interactions.map(interaction => { if (interaction.id === interactionId) { return { ...interaction, is_session_public: shouldBePublic } } else { return interaction } }) }
                        } else {
                            return session;
                        }
                    })
                }
            })
    },
});

export const { addInteractionEvent, addSessionAction, setCurrentSessionId, setCurrentInteractionId, setLastCreatedSessionId, setLastCreatedInteractionId, setCurrentContentReferences, resetCurrentContentReferences, removeMemberFromSession, removeContentFromSession } = sessionSlice.actions;
export const selectSessionsLoadedState = (state: RootState) => state.session.sessionsLoadedState;
export const selectAllSessions = (state: RootState) => state.session.sessions;
export const selectSessionById = (sessionId?: string) => (state: RootState) => state.session.sessions.find(session => session.id === sessionId);
export const selectSessionsById = (sessionIds: string[]) => (state: RootState) => state.session.sessions.filter(session => sessionIds.includes(session.id));
export const selectCurrentSessionId = (state: RootState) => state.session.currentSessionId;
export const selectCurrentInteractionId = (state: RootState) => state.session.currentInteractionId;
export const selectUrlUploadState = (state: RootState) => state.session.urlUploadState;
export const selectUrlUploadError = (state: RootState) => state.session.urlUploadError;
export const selectFileUploadState = (state: RootState) => state.session.fileUploadState;
export const selectFileUploadError = (state: RootState) => state.session.fileUploadError;
export const selectUpdateSessionState = (state: RootState) => state.session.updateSessionState;
export const selectLastCreatedSessionId = (state: RootState) => state.session.lastCreatedSessionId;
export const selectLastCreatedInteractionId = (state: RootState) => state.session.lastCreatedInteractionId;
export const selectInteraction = (sessionId?: string, interactionId?: string) => (state: RootState) => state.session.sessions.find(session => session.id === sessionId)?.interactions.find(interaction => interaction.id === interactionId);
export const selectCurrentContentReferences = (state: RootState) => state.session.currentContentReferences;
export const selectContentChunk = (chunkId?: string) => (state: RootState) => chunkId ? state.session.contentChunks[chunkId] : undefined;
export const selectPublishedSession = (username?: string, sessionName?: string) => (state: RootState) => state.session.sessions.find(session => session.published_session?.id === getPublishedSessionId(username, sessionName));
export const selectSession = (sessionId?: string, username?: string, sessionName?: string) => {
    if (sessionId) {
        return selectSessionById(sessionId)
    } else if (username && sessionName) {
        return selectPublishedSession(username, sessionName)
    } else {
        return () => undefined;
    }
}

export const selectFetchQueryNewInteractionState = (state: RootState) => state.session.fetchQueryNewInteractionState;

export default sessionSlice.reducer;
