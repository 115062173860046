import { getContentSource, getContentType } from "../components/cards/sourceCard";
import { getMostPublicSessionPath } from "../hooks/useUrl";
import { getMemberInfo } from "../members";
import { getSessionImage, getSessionLinkedTime } from "../sessionUtils";
import { datetimeStringToDate } from "../time";
import { Content, Member, Session, Source } from "../types";

export const sourceFromContent = (content: Content, session: Session): Source => {
    const source = content?.source

    const { title = content.name, description, author = "Unknown" } = content.tags

    const creatorMember = session.members.find(member => member.user.id === content.created_by)
    const creatorMemberInfo = getMemberInfo(creatorMember)

    const { image = "" } = content.tags
    const contentType = getContentType(content)

    return {
        id: content.id,
        source,
        title,
        description,
        addedBy: creatorMemberInfo,
        addedTime: datetimeStringToDate(content.created_time),
        image,
        isPrimary: content.is_primary,
        author,
        icon: getContentSource(content).icon,
        type: contentType,
        references: session.statistics.references_per_content[content.id] || 0,
        base: content
    }
}

export const sourceFromLinkedSession = (linkedSession: Session, session: Session): Source => {
    const creatorMember = linkedSession.members.find(member => member.user.id === linkedSession.created_by) as Member
    const creatorMemberInfo = getMemberInfo(creatorMember)

    const linkedTime = getSessionLinkedTime(session, linkedSession.id);

    const url = `${window.location.origin}/#${getMostPublicSessionPath(linkedSession)}`

    return {
        id: linkedSession.id,
        source: url,
        title: linkedSession.name,
        description: linkedSession.short_description || "",
        addedBy: creatorMemberInfo,
        addedTime: linkedTime,
        image: getSessionImage(linkedSession),
        isPrimary: false,
        author: creatorMemberInfo.displayName,
        icon: "/favicon.ico",
        type: "LINKED_SESSION",
        references: 0,
        base: linkedSession
    }
}