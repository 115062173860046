import { Interaction, Member } from "../../types";
import { Autocomplete, Avatar, Box, Divider, MenuItem, SxProps, TextField, Typography } from "@mui/material";
import { Modal, ModalActions, ModalButton, ModalContents, ModalFormField, ModalHeader } from "./modal";
import { useState } from "react";
import KeyIcon from '@mui/icons-material/Key';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { useAppDispatch } from "../../app/hooks";
import { fetchSetInteractionSessionPublic } from "../../features/session/sessionSlice";

interface InviteMemberModalProps {
    open: boolean
    handleClose: () => void
    invite: (userId: string) => void
    interaction: Interaction
    members: Member[]
    sessionId: string
}

const ModalRow = ({ children, onClick }: { children: React.ReactNode, onClick?: () => void }) => {
    let sx = { display: 'flex', alignItems: 'center', gap: '8px' } as SxProps;

    if (onClick) {
        sx = { ...sx, cursor: "pointer" };
    }

    return <Box sx={sx} onClick={onClick}>{children}</Box>
}

type AccessLevels = "INVITE" | "PUBLIC";

const GeneralAccessLevel = ({ interaction, sessionId }: { interaction: Interaction, sessionId: string}) => {
    const dispatch = useAppDispatch();

    const accessLevel = interaction.is_session_public ? "PUBLIC" : "INVITE";

    const accessLevelItems = {
        "INVITE": {
            icon: <KeyIcon />,
            text: "Only invited people can access"
        },
        "PUBLIC": {
            icon: <LanguageOutlinedIcon />,
            text: "Anyone in the space can access"
        }
    }

    const { icon, text } = accessLevelItems[accessLevel];

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const accessLevel = e.target.value as AccessLevels;
        const shouldBePublic = accessLevel === "PUBLIC";
        dispatch(fetchSetInteractionSessionPublic({ sessionId: sessionId, interactionId: interaction.id, shouldBePublic: shouldBePublic }));
    }

    return <ModalRow>
        <Avatar>{icon}</Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <TextField value={accessLevel} label="" select variant="standard" InputProps={{ disableUnderline: true }} onChange={onChange}>
                <MenuItem value={"INVITE"}>Invite</MenuItem>
                <MenuItem value={"PUBLIC"}>Public</MenuItem>
            </TextField>
            <Typography variant="body1" color="text.secondary">{text}</Typography>
        </Box>
    </ ModalRow>
}

const compareMembersByEmail = (a: Member, b: Member) => a.user.email.localeCompare(b.user.email);

export function InviteInteractionMemberModal({ open, handleClose, invite, interaction, members, sessionId}: InviteMemberModalProps) {
    const [currentMember, setCurrentMember] = useState<Member | null>(null);

    const currentInteractionMembers = members.filter(m => interaction.members.includes(m.user.id)).sort(compareMembersByEmail)
    const currentInteractionMemberIds = currentInteractionMembers.map(m => m.user.id);
    const currentInteractionNonMembers = members.filter(m => !currentInteractionMemberIds.includes(m.user.id)).sort(compareMembersByEmail);
    const sortedMembers = [...currentInteractionNonMembers, ...currentInteractionMembers];

    const doClose = () => {
        handleClose();
        setCurrentMember(null);
    }

    const doInvite = (userId: string) => {
        invite(userId);
        doClose();
    }

    const onMemberChange = (event: any, value: Member | null) => {
        setCurrentMember(value);
    }

    return <Modal
        open={open}
        onClose={doClose}
    >
        <ModalContents>
            <ModalHeader >
                {`Share "${interaction.name}"`}
            </ModalHeader>
            <ModalFormField title="Email">
                <Autocomplete
                    disablePortal
                    options={sortedMembers}
                    fullWidth
                    onChange={onMemberChange}
                    value={currentMember}
                    renderInput={(params) => <TextField {...params} placeholder="Email" />}
                    getOptionDisabled={(option) => currentInteractionMembers.includes(option)}
                    getOptionLabel={(option) => option.user.email}
                />
            </ModalFormField>
            <GeneralAccessLevel interaction={interaction} sessionId={sessionId} />
        </ModalContents>
        <Divider />
        <ModalActions>
            <ModalButton onClick={doClose} variant="text" color="primary" size="large">Close</ModalButton>
            <ModalButton disabled={!currentMember} onClick={() => currentMember && doInvite(currentMember?.user.id)} variant="contained" color="contrast" size="large">Invite</ModalButton>
        </ModalActions>
    </Modal>
}