import React from "react";

export type UseModalProps = {
    open: boolean;
    onClose: () => void;
    onClick: () => void;
}

export const useModal = (): UseModalProps => {
    const [open, setOpen] = React.useState(false);

    const onClose = () => {
        setOpen(false);
    }

    const onClick = () => {
        setOpen(true);
    }

    return { open, onClose, onClick };
}