import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from '../../auth';

interface RequireAdminProps {
    children: JSX.Element;
}
export function RequireAdmin({ children }: RequireAdminProps) : JSX.Element {
    const location = useLocation();
    const { loading, isAuthenticated, isAdmin} = useAuth(); 

    if (loading) {
        return <></>
    }


    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    if (!isAdmin) {
        return <Navigate to="/" replace />;
    }
    return children;
}