import { Box, Button, Typography } from "@mui/material";
import React from "react";
import AuthPage from "./auth";
import { Logo } from "../logo";
import { Link } from "react-router-dom";

export const GuestErrorPage = () => {
    return <AuthPage>
        <Box className="auth-action-panel-header">
            <Logo />
        </Box>

        <Box className="auth-error-wrapper">
            <Typography variant="h1">Sorry!</Typography>
            <Typography variant="h3">You must have an account to access this.</Typography>
            <Box sx={{ width: "50%", textAlign: "center"}} className="spacing-large">
                <Button variant="contained" color="contrast" fullWidth component={Link} to="/login" state={{hideGuest: true}}>Log in</Button>
                <Typography variant="body1" sx={{padding: "8px"}}>or</Typography>
                <Button color="contrast" fullWidth component={Link} to="/signup">Sign up</Button>
            </Box>
        </Box>
    </AuthPage>
}