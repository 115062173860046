import { ActionsPopover, ActionsPopoverItem } from "./actionsPopover"
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { PopoverProps, useColorScheme } from "@mui/material";
import React from "react";
import { ProfileModal } from "../modals/profileModal";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { feedback } from "../../App";
import { deleteGuestUserIfExists } from "../../guest";


export const ProfileActionsPopover = ({ anchorOrigin, transformOrigin, ...props }: PopoverProps) => {

    const navigate = useNavigate();
    const { isAdmin, isAuthenticated, isGuest, amplifyUser} = useAuth();

    const [openProfileModal, setOpenProfileModal] = React.useState(false);
    const { mode, setMode } = useColorScheme();

    const close = () => {
        props.onClose?.({}, 'escapeKeyDown');
    }

    const isLight = mode === 'light';

    const toggleMode = () => {
        setMode(isLight ? 'dark' : 'light');
    }

    const onProfileClicked = () => {
        setOpenProfileModal(true);
        close();
    }

    const onThemeClicked = () => {
        toggleMode()
    }

    const onAdminClicked = () => {
        close();
        navigate('/admin');
    }

    const onFeedbackClicked = () => {
        close();
        feedback.openDialog();
    }

    const onHelpClicked = () => {
        close();
        navigate('/help');
    }

    const onSignOutClicked = async () => {
        if (isAuthenticated) {
            Auth.signOut().then(() => {
                window.location.href = "/";
            })
        } else if (isGuest) {
            await deleteGuestUserIfExists();
            navigate('/login', { state: { hideGuest: true } });
        }
    }

    const passedProps = {
        ...props,
        anchorOrigin: anchorOrigin || {
            vertical: 'top',
            horizontal: 'center',
        },
        transformOrigin: transformOrigin || {
            vertical: 'bottom',
            horizontal: 'center',
        }
    }

    return <>
        {amplifyUser && <ProfileModal open={openProfileModal} onClose={() => setOpenProfileModal(false)} onAccept={() => { }} amplifyUser={amplifyUser} />}
        <ActionsPopover {...passedProps}
            footerAction={<ActionsPopoverItem onClick={onSignOutClicked} text="Sign out" icon={<LogoutOutlinedIcon />} />}
        >
            {isAuthenticated && <ActionsPopoverItem onClick={onProfileClicked} text="My profile" icon={<AccountCircleOutlinedIcon />} />}
            {
                isAdmin &&
                <ActionsPopoverItem onClick={onAdminClicked} text="Admin" icon={<AdminPanelSettingsOutlinedIcon />} />
            }
            {/* <ActionsPopoverItem text="Login and safety" icon={<LockOutlinedIcon />} /> */}
            {/* <ActionsPopoverItem text="Preferences" icon={<SettingsOutlinedIcon />} /> */}
            <ActionsPopoverItem onClick={onThemeClicked} text="Theme" icon={<DarkModeOutlinedIcon />} />
            {isAuthenticated && <ActionsPopoverItem text="Send feedback" onClick={onFeedbackClicked} icon={<FeedbackOutlinedIcon />} />}
            <ActionsPopoverItem onClick={onHelpClicked} text="Help" icon={<HelpOutlineOutlinedIcon />} />
            
        </ActionsPopover>
    </>
}
