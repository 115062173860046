import { Box, Button } from "@mui/material";
import { Logo } from "../logo";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";


export const LegalPage = ({ children }: { children: React.ReactNode }) => {

    const navigate = useNavigate();

    const { isAuthenticated, isGuest } = useAuth();

    const notLoggedIn = !isAuthenticated && !isGuest;

    return <>
        <Box component="header" className="internal-page-header">
            <Logo />
            { notLoggedIn && <Button variant="contained" color="contrast" onClick={() => navigate("/login")}>Log in</Button> }
        </Box>
        <Box className="internal-page">
            <Box className="internal-page-content">
                <Box className="legal-text">
                    {children}
                    <Box className="spacing-medium" />
                </Box>
            </Box>
        </Box>
    </>
}