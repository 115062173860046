import { Box, CircularProgress, Typography } from "@mui/material"
import AuthPage from "./auth";
import { Logo } from "../logo";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { createInviteFromCheckoutSession } from "../api";



const PaymentSuccessPageBody = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const checkoutSessionId = new URLSearchParams(location.search).get('checkout_session_id');

    useEffect(() => {
        if (checkoutSessionId) {
            createInviteFromCheckoutSession(checkoutSessionId)
            .then(({data}) => {
                const invitationId = data?.invitation_id;
                if (invitationId) {
                    navigate(`/betaSignup/${invitationId}`)
                } else {
                    navigate("/paymentFailure")
                }
            })
        }
    }, [checkoutSessionId, navigate])

    if (!checkoutSessionId) {
        return <Navigate to="/paymentFailure" />
    }


    return <>
        <Box className="auth-action-panel-header">
            <Logo />
        </Box>

        <Box className="auth-error-wrapper">
            <Typography variant="h1">Thank you for subscribing!</Typography>
            <Box className="spacing-small" />
            <Typography variant="h3">You are being redirected to the signup page.</Typography>
            <Box className="spacing-small" />
            <CircularProgress />
        </Box>
    </>
}

export const PaymentSuccessPage = () => {
    return <AuthPage>
        <PaymentSuccessPageBody />
    </AuthPage>
}

