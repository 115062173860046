import { useMemo } from "react";
import { useAppDispatch } from "../app/hooks";
import { fetchLinkPublishedSession, fetchLinkSession, fetchUploadUrl } from "../features/session/sessionSlice";
import { normalizeUrl } from "../urls";

const getPrivateSessionIdRegex = (host: string) => new RegExp(`https?:\\/\\/${host}\\/#\\/([a-f0-9-]+).*`);
const getPublicSessionIdRegex = (host: string) => new RegExp(`https?:\\/\\/${host}\\/#\\/p\\/(\\w+)\\/([\\w\\-\\_~]+).*`);

export const useUploadUrl = () => {
    const dispatch = useAppDispatch();
    const host = window.location.host;

    const { privateSessionIdRegex, publicSessionIdRegex } = useMemo(() => {
        return {
            privateSessionIdRegex: getPrivateSessionIdRegex(host),
            publicSessionIdRegex: getPublicSessionIdRegex(host)
        }
    }, [host]);

    const getPrivateSessionId = (text: string) => {
        const match = text.match(privateSessionIdRegex);
        if (match) {
            return match[1];
        }
    }

    const getPublicSessionId = (text: string) => {
        const match = text.match(publicSessionIdRegex);
        if (match) {
            return { username: match[1], sessionName: match[2] };
        }
    }

    const uploadUrl = (text: string, sessionId: string) => {
        const trimmedText = text.trim();
        const privateSessionId = getPrivateSessionId(trimmedText);
        const publicSessionIdRegex = getPublicSessionId(trimmedText);

        if (privateSessionId) {
            dispatch(fetchLinkSession({ sessionId, linkedSessionId: privateSessionId }));
        } else if (publicSessionIdRegex) {
            const { username, sessionName } = publicSessionIdRegex;
            dispatch(fetchLinkPublishedSession({ sessionId, username: username, sessionName: sessionName }));
        } else {
            const normalizedUrl = normalizeUrl(trimmedText)
            dispatch(fetchUploadUrl({ sessionId, url: normalizedUrl }));

        }
    }

    return uploadUrl;

}
