import { useAppDispatch } from "../app/hooks";
import { VisuallyHiddenInput } from "../components/visuallyHiddenInput";
import { enqueueSnackbar } from "./notifications/notificationsSlice";

const acceptedExtensions = ['.docx', '.pdf', '.doc', '.txt', '.html', '.htm', '.jpg', '.jpeg', '.png']
const acceptedExtensionsString = acceptedExtensions.join(',');

const maxMB = 50;

const maxSize = maxMB * 1024 * 1024;

export const FileInput = ({ onSelected }: { onSelected: (file: File) => void }) => {
    const dispatch = useAppDispatch();

    return <VisuallyHiddenInput type="file" accept={acceptedExtensionsString} multiple={false} onChange={e => {
        const files = e.target.files;
        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.size > maxSize) {
                    dispatch(
                        enqueueSnackbar({
                            message: `Failed to upload file ${file.name}: Max size is ${maxMB}MB.`,
                            options: {
                                variant: "error",
                            },
                        })
                    );
                } else {
                    onSelected(file);
                }
            }
        }
        e.target.value = "";
    }} />
}