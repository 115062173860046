import { useNavigate } from "react-router-dom";
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import { ConfirmModal } from "./confirmModal";

interface GuestErrorModalProps {
    open: boolean;
    onAccept: () => void;
    onClose: () => void;
}

export const GuestErrorModal = ({ open, onClose, onAccept }: GuestErrorModalProps) => {

    const navigate = useNavigate();
    const icon = <LockPersonOutlinedIcon sx={{ fontSize: '2rem' }}/>

    const doAccept = () => {
        onAccept();
        navigate("/login", { state: { hideGuest: true } });
    }

    return <ConfirmModal title="Sorry!" body="You must have an account to do this." acceptText="Log in" open={open} onClose={onClose} onAccept={doAccept} icon={icon} cancelText="Join waitlist" />
};
