import { Box, CircularProgress, Typography } from "@mui/material";
import { Modal, ModalContents } from "./modal";


export const CreatingGuestUserModal = ({ open, onClose }: {open: boolean, onClose: () => void}) => {


    return <Modal open={open} onClose={onClose} small>
        <ModalContents>
            <Box className="confirm-modal-contents">
                    <Box className="confirm-modal-icon">
                        <CircularProgress />
                    </Box>
                <Typography variant="h1">Creating guest user</Typography>
                <Typography variant="body1">Just need to make sure you're not a robot...</Typography>
            </Box>
        </ModalContents>
    </Modal>;
};