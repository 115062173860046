import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getUserNotifications, markAllUserNotificationsAsSeen, markUserNotificationAsSeen } from "../../api";
import { UserNotification } from "../../types";

type RequestStatus = 'idle' | 'loading' | 'failed' | 'succeeded';

export interface UserNotificationsState {
    notifications: UserNotification[]
    getNotificationsState: RequestStatus
}

const initialState: UserNotificationsState = {
    notifications: [],
    getNotificationsState: 'idle',
}

export const fetchUserNotifications = createAsyncThunk(
    'userNotifications/get',
    async () => {
        const response = await getUserNotifications();
        return response.data;
    }
);

export const fetchMarkNotificationAsSeen = createAsyncThunk(
    'userNotifications/markAsSeen',
    async ({ notificationId }: { notificationId: string }) => {
        await markUserNotificationAsSeen(notificationId);
    }
);

export const fetchMarkAllNotificationAsSeen = createAsyncThunk(
    'userNotifications/markAllAsSeen',
    async () => {
        await markAllUserNotificationsAsSeen();
    }
);

const userNotificationsSlice = createSlice({
    name: 'userNotifications',
    initialState,
    reducers: {
        addNotification: (state, action) => {
            state.notifications.push(action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserNotifications.pending,
                (state, action) => {
                    return { ...state, getNotificationsState: 'loading' }
                })
            .addCase(fetchUserNotifications.fulfilled,
                (state, action) => {
                    return { ...state, getNotificationsState: 'succeeded', notifications: action.payload }
                })
            .addCase(fetchUserNotifications.rejected,
                (state, action) => {
                    return { ...state, getNotificationsState: 'failed' }
                })
            .addCase(fetchMarkNotificationAsSeen.fulfilled,
                (state, action) => {
                    return {
                        ...state, notifications: state.notifications.map(notification => {
                            if (notification.id === action.meta.arg.notificationId) {
                                return { ...notification, seen: true }
                            }
                            return notification;
                        })
                    }
                })
            .addCase(fetchMarkAllNotificationAsSeen.fulfilled,
                (state, action) => {
                    return {
                        ...state, notifications: state.notifications.map(notification => {
                            return { ...notification, seen: true }
                        })
                    }
                })

    }
});
export const { addNotification } = userNotificationsSlice.actions;
export default userNotificationsSlice.reducer;
export const selectUserNotifications = (state: RootState) => state.userNotifications.notifications;
export const selectUserNotificationsState = (state: RootState) => state.userNotifications.getNotificationsState;