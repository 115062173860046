import { useEffect } from 'react';

import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { Box, Button, Typography } from '@mui/material';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import AuthPage from './auth';
import { Logo } from '../logo';
import { createGuestUser } from '../api';
import { CreatingGuestUserModal } from '../components/modals/creatingGuestUserModal';
import { useModal } from '../hooks/useModal';
import { manageSubscriptionUrl } from '../App';

const formFields = {
    signIn: {
        username: {
            label: '',
            placeholder: 'Email',
        },
        password: {
            label: '',
            placeholder: 'Password',
        },
    }
}

const ContinueAsGuest = ({ onClick }: { onClick: () => void }) => {
    return <Box className="continue-as-guest-box">
        <Button variant='contained' onClick={onClick}>Continue as guest</Button>
        <Typography variant='body2' color='text.secondary'>
            Guest usage is protected by reCAPTCHA and the Google
            <MuiLink component={Link} to="https://policies.google.com/terms"> Terms of Service</MuiLink> and
            <MuiLink component={Link} to="https://policies.google.com/privacy"> Privacy Policy</MuiLink> apply.
        </Typography>
    </Box>
}

const LoginPageActionPanel = () => {

    const location = useLocation();
    let from = location.state?.from?.pathname || '/';
    const hideGuest = location.state?.hideGuest;

    const showGuest = from !== "/" || hideGuest;

    const creatingGuestUserModal = useModal();
    const navigate = useNavigate();

    const onContinueAsGuest = async () => {
        creatingGuestUserModal.onClick();
        await createGuestUser();
        creatingGuestUserModal.onClose();
        navigate(from, { replace: true });
    }

    return <>
        <CreatingGuestUserModal open={creatingGuestUserModal.open} onClose={creatingGuestUserModal.onClose} />
        <Box className="auth-action-panel-header">
            <Logo />
        </Box>

        <Box className="auth-form-wrapper">
            <Authenticator hideSignUp formFields={formFields} loginMechanisms={['email']}>
                {({ user }) => (
                    <>
                        {user?.attributes?.email_verified ?
                            <Navigate to={from} replace />
                            :
                            <Navigate to={"/verify"} />}
                    </>
                )}
            </Authenticator>
            <Box className="auth-action-panel-content-width">
                <Button color="contrast" fullWidth component={Link} to="/signup" sx={{marginInline: "auto"}}>Sign up</Button>
                {showGuest && <ContinueAsGuest onClick={onContinueAsGuest} />}
            </Box>
        </Box>
        <Box className="auth-action-panel-footer">
            <Typography variant='body1' color='text.secondary'>
                By continuing, you agree to Brua's <MuiLink component={Link} to="/termsOfService">Terms of Service</MuiLink> and <MuiLink component={Link} to="/privacyPolicy">Privacy Policy</MuiLink>.
            </Typography>
            <Typography variant='body1' color='text.secondary'>
                <MuiLink component={Link} to={manageSubscriptionUrl}>Manage subscription</MuiLink>.
            </Typography>
        </Box>
    </>
}

const LoginPage = () => {

    const navigate = useNavigate();

    const { route } = useAuthenticator(context => [context.route]);


    useEffect(() => {
        if (route === "verifyUser") {
            navigate("/verify", { replace: true });
        }
    }, [route, navigate])


    return <AuthPage>
        <LoginPageActionPanel />
    </AuthPage>
}

export default LoginPage;