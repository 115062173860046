import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { inviteUser, getInvitations } from "../api"
import { Invitation } from "../types"
import { datetimeStringToDatetimeReadable } from "../time"

const InvitationsTable = ({ invitations }: { invitations: Invitation[] }) => {
    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Id</TableCell>
                    <TableCell align="right">Created</TableCell>
                    <TableCell align="right">Invited by</TableCell>
                    <TableCell align="right">Claimed</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {invitations.map((invitation) => (
                    <TableRow
                        key={invitation.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {invitation.email}
                        </TableCell>
                        <TableCell align="right">{invitation.id}</TableCell>
                        <TableCell align="right">{datetimeStringToDatetimeReadable(invitation.created_datetime)}</TableCell>
                        <TableCell align="right">{invitation.invited_by}</TableCell>
                        <TableCell align="right">{invitation.claimed_by ? "Yes" : "No"}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

export const AdminPage = () => {

    const [userEmail, setUserEmail] = useState<string>("")

    const [invitations, setInvitations] = useState<Invitation[]>([])
    const [invitationsLoaded, setInvitationsLoaded] = useState<boolean>(false)

    const doGetInvitations = async () => {
        getInvitations().then((response) => {
            setInvitations(response.data);
            setInvitationsLoaded(true);
        })
    }


    const fetchInviteUser = async () => {
        inviteUser(userEmail).then((response) => {
            doGetInvitations();
        })
    }

    useEffect(() => {
        if (!invitationsLoaded) {
            doGetInvitations();
        }

    }, [invitationsLoaded]);

    return <Box sx={{ padding: "2em", display: "flex", flexDirection: "column", gap: "1em" }}>
        <Typography variant="h3">Invite user</Typography>
        <Box sx={{ display: "flex", gap: "1em" }}>
            <TextField sx={{ width: "500px" }} type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
            <Button variant="contained" onClick={fetchInviteUser}>Invite</Button>
        </Box>
        <Box sx={{ maxHeight: "500px", overflowY: "auto" }}>
            <Typography variant="h3">Invitations</Typography>
            <InvitationsTable invitations={invitations} />
        </Box>
    </Box>
}